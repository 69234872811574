import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Store } from 'src/app/models/store';
import { Inventory } from 'src/app/models/inventory';
import { StoreService } from 'src/app/services/store.service';
import { AccessService } from 'src/app/services/access.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from "src/app/models/company";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
  providers: [UtilsService, InventoryService, AccessService, StoreService, CompanyService],
})
export class InventoryComponent implements OnInit {
  // MODELS VARIABLE
  public inventory = {} as Inventory;
  public inventoryAux = {} as Inventory;
  public store = {} as Store;
  company = {} as Company;
  public companyInventoryView: number;
  public isInventoryView: boolean;
  public isViewBeginning: boolean;
  public isViewSeries: boolean;
  public isViewPrices: boolean;
  // public company;
  public userCode: number;
  public userRole: number;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  public noCredit: boolean;
  public randomCode: number;
  public settingsSerial = {
    columns: {
      codigo: {
        title: "Código",
      },

      nombre: {
        title: "Nombre",
      },
      cad: {
        title: "Fecha de vencimiento"
      },
      lote: {
        title: "Lote"
      },
      stock: {
        title: "Stock"
      },      
    },
  };

  public settings = {
    columns: {
      inventoryBusinessCode: {
        title: "Código",
      },

      inventoryName: {
        title: "Nombre",
      },
      productName:{
        title: "Categoria"
      },
      inventoryPriceCost: {
        title: "Costo",
        type: "number",
      },
      inventoryPrice: {
        title: "Precio de Venta",
        type: "number",
      },
      inventoryPriceDiscount: {
        title: "Precio con Descuento",
        type: "number",
      },
      inventorySalePrice: {
        title: "Precio Promocion",
        type: "number",
      },
      inventoryQuantity: {
        title: "Stock",
      },
      inventoryMin: {
        title: "Stock Minimo",
      },
      inventoryMax: {
        title: "Stock Maximo",
      },
      inventoryDescription: {
        title: "Descripción",
      },
    },
  };

  /* 
    inventorySerialCode: e.InventorySerialCode,
    inventorySerialSerial: e.InventorySerialCode,
    inventorySerialLote: e.inventorySerialLote,
    inventorySerialVencimiento: e.inventorySerialLote,
    inventorySerialStock: e.inventorySerialStock,
    inventoryBusinessCode: e.inventoryBusinessCode,
    inventoryDescription: e.inventoryDescription,
    inventoryName: e.inventoryName,
    inventoryQuantity: e.inventoryQuantity,
  */
  public settingsSerialStore = {
    columns: {
      inventorySerialCode: {
        title: "Código Serie",
      },

      inventorySerialSerial: {
        title: "Serie",
      },
      inventorySerialLote: {
        title: "Lote"
      },
      inventorySerialVencimiento: {
        title: "Vencimiento"
      },
      inventorySerialStock: {
        title: "Stock Serie"
      },
      inventoryBusinessCode: {
        title: "Codigo Producto"
      },
      inventoryDescription: {
        title: "Descripcion"
      },
      inventoryName: {
        title: "Nombre"
      },
      inventoryQuantity: {
        title: "Stock Producto"
      },
    },
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public inventoryList: any = []; //Inventory[] = new Array();
  public itemList = new Array();
  public itemListStore = new Array();

  // VARIABLE FOR SELECT
  public productList: any[] = new Array();
  public selectedItemsProduct = [];
  public dropdownSettingsProduct = {};

  public storeList: any[] = new Array();
  public storeListSelect: any[] = new Array();
  public selectedItemsStore = [];
  public dropdownSettingsStore = {
    singleSelection: false,
    badgeShowLimit: 1,
    text: "Seleccione Sucursal...",
    searchPlaceholderText: "Buscar",
    enableCheckAll: true,
    enableUnCheckAll: true,
    selectAllText: "Seleccionar todas",
    unSelectAllText: "Deseleccionar todas",
    enableSearchFilter: true,
    lazyLoading: false,
    noDataLabel: "Resultados:",
  };

  public dropdownSettingsStores = {
    singleSelection: true,
    badgeShowLimit: 1,
    text: "Seleccione Sucursal...",
    searchPlaceholderText: "Buscar",
    enableCheckAll: true,
    enableUnCheckAll: true,
    selectAllText: "Seleccionar todas",
    unSelectAllText: "Deseleccionar todas",
    enableSearchFilter: true,
    lazyLoading: false,
    noDataLabel: "Resultados:",
  };

  public storeInv: number;
  public storeInvN: string;

  constructor(
    private modalService: NgbModal,
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _storeService: StoreService,
    private _accessService: AccessService,
    private _inventoryService: InventoryService,
    private _companyService: CompanyService,
  ) {}

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem("data"));
    this.companyCode = _data.companyCode
      ? parseInt(_data.companyCode.toString())
      : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeName = _data.userCode ? _data.storeName.toString() : 0;

    let _dataUser = JSON.parse(localStorage.getItem("user"));
    console.log('_dataUser', _dataUser);
    this.userRole = _dataUser.userRole;
    console.log('role', this.userRole);

    // VIEWS
    this.viewBeginning();
    await this.getCompany(this.companyCode);

    // LOAD VARIABLE    
    this.isEdit = false;
    this.isDisabled = false;
    this.inventory.inventoryPrice = 0;
    this.inventory.inventoryPriceCost = 0;
    this.inventory.inventorySalePrice = 0;
    this.inventory.inventoryPriceDiscount = 0;
    this.inventory.inventoryMinPrice = 0;
    this.inventory.inventoryQuantity = 0;
    this.inventory.inventoryMin = 0;
    this.inventory.inventoryMax = 0;
    this.inventory.inventoryPurchaseDetail = 0;

    // LOAD INFORMATION
    const store = await this._storeService.getOne(this.storeCode);
    this.store = store.records[0];
    //this.store.storeMain === 1 && this.getStoreByCompany(this.companyCode);
    this.getStoreByCompany(this.companyCode);

    this.getProductList();
    this.getSelectSettingProduct(this.selectedItemsProduct);

    this.randomCode = this.getRandomInt(99999, 99999999);
    console.log(this.randomCode.toString());
  }

    ///////////////////////COMPANY///////////////////////
    getCompany(company) {
      return this._companyService.getOne(company).then(
        response => {
          if (response.result) {
            this.company = response.records[0];            
            this.companyInventoryView = this.company.companyInventoryStores;
            if(this.companyInventoryView == 1){
              this.isInventoryView = true;
              this.getAll(this.storeCode);
              // this.getAllCompany(company);
            }
            console.log('Company -> ', this.company);
            console.log('Company View -> ', this.isInventoryView);
          } else {
            console.log(response.message);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.inventoryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////
  getProductList() {
    this.loading = true;

    return this._inventoryService
      .getAllProductCompany()
      .then(
        async (response) => {
          if (response.result) {
            this.productList = [];            

            response.records.forEach((e) => {
              this.productList.push({
                id: e.productCode,
                itemName: e.productName,
                productCompany: e.productCompany
              });              
            });

            //console.log('product',this.productList);

            const _selectedCategory = await this.productList.filter(x => x.productCompany == this.companyCode);
            // console.log('producto',this.productList);
            // console.log('producto company',_selectedCategory);

            this.productList = _selectedCategory;

          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  getSelectSettingProduct(selected) {
    selected != null ? (this.selectedItemsProduct = [selected]) : "";

    this.dropdownSettingsProduct = {
      singleSelection: true,
      text: "Seleccione...",
      searchPlaceholderText: "Buscar",
      enableCheckAll: false,
      enableSearchFilter: true,
      lazyLoading: true,
    };
  }

  onItemSelectProduct(item: any) {
    this.inventory.inventoryProduct = item.id;
  }

  ///////////////////////CRUD///////////////////////
  // GET ALL INVENTORY
  getAll(storeCode) {
    this.loading = true;
    // console.log(storeCode, "este es el store code para nail center")
    //return this._inventoryService.getAllByStore(storeCode).then(
    return this._inventoryService
      .getAllByStoreStatus(storeCode)
      .then(
        (response) => {
          if (response.result) {
            this.inventoryList = response.records;
            this.temp = this.inventoryList;
            console.log("Inventory Status", this.inventoryList);
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  getAllCompany(companyCode) {
    this.loading = true;
    // console.log(storeCode, "este es el store code para nail center")
    //return this._inventoryService.getAllByStore(storeCode).then(
    return this._inventoryService
      .getAllByCompanyStatus(companyCode)
      .then(
        (response) => {
          if (response.result) {
            this.inventoryList = response.records;
            this.temp = this.inventoryList;
            console.log("Inventory Company Status", this.inventoryList);
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  public count: number;
  // GET ITEM
  getItems(inventoryCode, inventoryBusinessCode) {
    console.log(inventoryBusinessCode, "este es el code del producto con series");
    console.log("this.storeCode", 0);
    this.loading = true;
    return this._inventoryService
      .getOneItemsSerial(inventoryCode,0)
      .then(
        (response) => {
          if (response.result) {
            this.itemList = [];
            let serie = response.records[0].series;
            let nombre = response.records[0].inventoryname;
            let quantity = response.records[0].inventoryquantity;

            let filtro = serie.filter(
              (x) =>
                x.status === "0"
            );            
            this.count = 0;
            console.log('filtro', filtro);
            ///////DISPONIBLES
            if (serie.length > 0) {
              for (var i = 0; i < filtro.length; i++) {
                let fechaCad = filtro[i].cad.split(" ");
                this.itemList.push({
                  inventorySerialCode: filtro[i].code,
                  inventorySerialFlag: filtro[i].series,
                  inventoryCode: inventoryCode,
                  serie: filtro[i] ? filtro[i].series : null,
                  nombre: nombre,
                  codigo: inventoryBusinessCode,
                  cad: fechaCad[0],
                  lote: filtro[i].lote,
                  stock: filtro[i].stock,
                });
                //}
              }
            }

            ///////NUEVOS
            for (var i = 0; i < this.count; i++) {
              this.itemList.push({
                inventorySerialCode: null,
                inventorySerialFlag: null,
                inventoryCode: inventoryCode,
                serie: null,
                nombre: nombre,
                codigo: inventoryBusinessCode,
              });
            }

            this.temp = this.itemList;
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }


  // GET ITEM STORE
  async getItemsStore() {
    // console.log(inventoryBusinessCode, "este es el code del producto con series");
    console.log("this.storeCode", this.storeCode);
    this.loading = true;
    return await this._inventoryService
      .getOneItemsSerial(0,this.storeCode)
      .then(
        async (response) => {
          if (response.result) {

            this.itemListStore = [];

            await response.records.forEach(e => {

              this.itemListStore.push({
                inventorySerialCode: e.InventorySerialCode,
                inventorySerialSerial: e.InventorySerialCode,
                inventorySerialLote: e.inventorySerialLote,
                inventorySerialVencimiento: e.inventorySerialLote,
                inventorySerialStock: e.inventorySerialStock,
                inventoryBusinessCode: e.inventoryBusinessCode,
                inventoryDescription: e.inventoryDescription,
                inventoryName: e.inventoryName,
                inventoryQuantity: e.inventoryQuantity,
              });

            });
            
            console.log('itemListStore', this.itemListStore);

            const checkListStore = this.itemListStore;
            const exportFile = new Array<any>();

            checkListStore.forEach((element) => {
              let json = {};
              Object.keys(this.settingsSerialStore.columns).forEach((item, index, arr) => {
                if (this.settingsSerialStore.columns[item].type === "number") {
                  json[this.settingsSerialStore.columns[item].title] = parseFloat(element[item]);
                } else {
                  json[this.settingsSerialStore.columns[item].title] = element[item];
                }
              });
              exportFile.push(json);
            });
            const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
            const wb: xlsx.WorkBook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Reporte");
            xlsx.writeFile(wb, "reporte-inventario-series.xlsx");

            // this.temp = this.itemList;
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }


  public pricesList = new Array();
  public inventoryCode: number = 0;
  viewPricesList($event, inventoryCode?) {
    $event.preventDefault();
    this.loading = true;

    this.inventory.inventoryName = this.activeRow.inventoryName;
    this.inventory.inventoryBusinessCode = this.activeRow.inventoryBusinessCode;

    inventoryCode == undefined
      ? (this.inventoryCode = this.activeRow.inventoryCode)
      : (this.inventoryCode = inventoryCode);

    return this._inventoryService
      .getOneRank(this.inventoryCode)
      .then(
        (response) => {
          if (response.result) {
            let prices = response.records;
            this.pricesList = [];
            for (var i = 0; i < 10; i++) {
              if (prices[i] != undefined) {
                this.pricesList.push({
                  no: i + 1,
                  inventoryBusinessCode: this.inventory.inventoryBusinessCode,
                  code: prices[i].inventoryPriceRankCode
                    ? prices[i].inventoryPriceRankCode
                    : null,
                  inventoryCode: this.activeRow.inventoryCode,
                  priceCode: prices[i].inventoryPriceRankCode
                    ? prices[i].inventoryPriceRankCode
                    : null,
                  minUnd: prices[i].inventoryPriceRankFrom
                    ? prices[i].inventoryPriceRankFrom
                    : null,
                  maxUnd: prices[i].inventoryPriceRankTo
                    ? prices[i].inventoryPriceRankTo
                    : null,
                  price:
                    prices[i].inventoryPriceRankPrice > 0
                      ? prices[i].inventoryPriceRankPrice
                      : 0,
                });
              } else {
                this.pricesList.push({
                  no: i + 1,
                  inventoryBusinessCode: this.inventory.inventoryBusinessCode,
                  code: null,
                  inventoryCode: this.activeRow.inventoryCode,
                  priceCode: null,
                  minUnd: null,
                  maxUnd: null,
                  price: null,
                });
              }
            }
            inventoryCode == undefined ? this.viewPrices(event) : "";
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  async saveAllPrices() {
    this.loading = true;
    await this.pricesList.forEach(async (e) => {
      // console.log('save all')
      if (e.minUnd != null && e.maxUnd != null && e.price != null) {
        await this.savePrice(
          e.no,
          e.priceCode,
          e.inventoryCode,
          e.minUnd,
          e.maxUnd,
          e.price,
          1
        );
      }
    });

    await this.viewPricesList(event, this.inventoryCode);
    this._utilsService.showNotification(
      4,
      "Operacion Finalizada <br> Los registros mal llenados no se guardon."
    );
  }

  async savePrice(no, code, inventory, min, max, price, saveAll?) {
    if ((min != null && max != null && price != null) || saveAll == 1) {
      let json;
      if (min == null || price == null) {
        inventory = null;
      }
      if (code == null) {
        json = {
          inventoryBusinessCode: this.inventory.inventoryBusinessCode,
          inventoryPriceRankInventoryCode: inventory,
          inventoryPriceRankFrom: min,
          inventoryPriceRankTo: max,
          inventoryPriceRankPrice: price,
        };
        const response = await this._inventoryService.insertPrice(json);
        if (saveAll == undefined) {
          response.message
            ? this._utilsService.showNotification(1, response.message)
            : "";
          response.errorMessage
            ? this._utilsService.showNotification(3, response.errorMessage)
            : "";
        }
      } else {
        json = {
          inventoryBusinessCode: this.inventory.inventoryBusinessCode,
          inventoryPriceRankCode: code,
          inventoryPriceRankInventoryCode: inventory,
          inventoryPriceRankFrom: min,
          inventoryPriceRankTo: max,
          inventoryPriceRankPrice: price,
        };
        const response = await this._inventoryService.updatePrice(json);
        if (saveAll == undefined) {
          response.message
            ? this._utilsService.showNotification(1, response.message)
            : "";
          response.errorMessage
            ? this._utilsService.showNotification(3, response.errorMessage)
            : "";
          await this.viewPricesList(event, this.inventoryCode);
        }
      }
    } else {
      this._utilsService.showNotification(
        2,
        "Datos no llenado es la posicion No. " + no
      );
    }
  }

  // GET ONE INVENTORY
  viewFunction($event) {
    $event.preventDefault();
    let series;
    this.activeRow.inventorySerialFlag == 1 ? (series = "SI") : (series = "NO");

    swal.fire({
      title: "Item:",
      imageUrl: `https://storage.googleapis.com/nail-center/BANCO%20DE%20IMAGENES/${this.activeRow.inventoryBusinessCode}.jfif`,
      imageHeight: 300,
      html:
        '<div class="text-left">' +
        "<b>Código: </b>" +
        this.activeRow.inventoryBusinessCode +
        "<br>" +
        "<b>Nombre: </b>" +
        this.activeRow.inventoryName +
        "<br>" +
        "<b>Series: </b>" +
        series +
        "<hr>" +
        "<b>Costo: </b>" +
        this.activeRow.inventoryPriceCost +
        "<br>" +
        "<b>Precio Venta: </b>" +
        this.activeRow.inventoryPrice +
        "<br>" +
        "<b>Precio Minimo de Venta: </b>" +
        this.activeRow.inventoryMinPrice +
        "<br>" +
        "<b>Precio con Descuento: </b>" +
        this.activeRow.inventoryPriceDiscount +
        "<br>" +
        "<b>Precio de Promoción: </b>" +
        this.activeRow.inventorySalePrice +
        "<hr>" +
        "<b>Stock: </b>" +
        this.activeRow.inventoryQuantity +
        "<br>" +
        "<b>Stock Mínimo: </b>" +
        this.activeRow.inventoryMin +
        "<br>" +
        "<b>Stock Máximo: </b>" +
        this.activeRow.inventoryMax +
        "<hr>" +
        "<b>Cód. de Orden: </b>" +
        this.activeRow.inventoryPurchaseDetail +
        "<br>" +
        "<b>Descripción: </b>" +
        this.activeRow.inventoryDescription +
        "<br></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false,
    });
  }

  // INSERT
  save() {
    console.log("Random Code: ", this.randomCode);
    //if (this.inventory.inventoryBusinessCode != null && this.inventory.inventoryName != null && this.inventory.inventoryName != '' && this.inventory.inventoryPrice >= 0 && this.inventory.inventoryPriceDiscount >= 0 && this.inventory.inventorySalePrice >= 0) {
    if (
      this.inventory.inventoryName != null &&
      this.inventory.inventoryName != "" &&
      this.inventory.inventoryPrice >= 0 &&
      this.inventory.inventoryPriceDiscount >= 0 &&
      this.inventory.inventorySalePrice >= 0
    ) {
      this.isDisabled = true;
      this.loading = true;

      this.inventory.inventoryStore = this.storeCode;
      this.inventory.inventorySerialFlag == true
        ? (this.inventory.inventorySerialFlag = 1)
        : (this.inventory.inventorySerialFlag = 0);
      this.inventory.inventoryStatus = 1;

      var splitted = this.inventory.inventoryName.split(" ", 4);
      console.log(splitted);

      let codname = "";
      splitted.forEach((split) => {
        console.log(split);
        console.log(split.substring(0, 2));
        codname += split.substring(0, 2);
      });

      if (
        this.inventory.inventoryBusinessCode == "" ||
        this.inventory.inventoryBusinessCode == undefined
      ) {
        var codBusinessGen = this.randomCode.toString() + codname;
        console.log("Generado: ", codBusinessGen);
        this.inventory.inventoryBusinessCode = codBusinessGen;
        this.inventory.inventoryBusinessCode =
          this.inventory.inventoryBusinessCode.replace(/'/g, "-");
      } else {
        console.log("No ramdom ", this.inventory.inventoryBusinessCode);
        this.inventory.inventoryBusinessCode =
          this.inventory.inventoryBusinessCode;
        this.inventory.inventoryBusinessCode =
          this.inventory.inventoryBusinessCode.replace(/'/g, "-");
      }

      // console.log(this.inventory);
      this._inventoryService
        .insert(this.inventory)
        .then(
          async (response) => {
            console.log(response);
            if (response.result == true) {
              this.isDisabled = false;


              if (this.companyInventoryView == 1) {
                //Convertir Archivo a base 64
                if (!this.isObjectEmpty(this.state)) {
                  const file = this.state.files.item(0);
                  await this.convertBase64(file).then(async (data: string) => {
                    await this._inventoryService.uploadImage(
                      this.inventory.inventoryBusinessCode,
                      data
                    );
                  });
                }
              }

              if (this.inventory.inventorySerialFlag == 0) {
                this.getAll(this.storeCode);
                this.cleanModel();
                this._utilsService.showNotification(1, response.message);
              } else if (this.inventory.inventorySerialFlag == 1) {
                let codigo = response.records[0].insertId;
                this.viewSeries(
                  event,
                  codigo,
                  this.inventory.inventoryBusinessCode
                );
              }
            } else {
              this.isDisabled = false;
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          (error) => {
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    } else {
      this._utilsService.showNotification(2, "Falto ingresar algun campo");
      this.isDisabled = false;
    }
  }

  flgInsertUpdate: number = 0;
  getPreviousValue(value) {
    value == null ? (this.flgInsertUpdate = 0) : (this.flgInsertUpdate = 1);
  }

  async viewSeries($event, codigo?, inventoryBusinessCode?) {
    $event.preventDefault();

    console.log('codigo----', codigo);
    if (codigo > 0) {
      await this.getItems(codigo, inventoryBusinessCode);
    } else {
      await this.getItems(this.activeRow.inventoryCode, inventoryBusinessCode);
    }

    this.isViewBeginning = false;
    this.isViewPrices = false;
    this.isViewSeries = true;
  }

  saveSerie($event) {
    $event.preventDefault();

    if (
      this.activeRow.inventorySerialFlag != null &&
      this.activeRow.inventorySerialFlag != ""
    ) {
      const json = {
        inventorySerialInventoryCode: this.activeRow.inventoryCode,
        inventorySerialSerial: this.activeRow.inventorySerialFlag,
      };
      this.loading = true;

      this._inventoryService
        .insertSerial(json)
        .then(
          async (response) => {
            if (response.result == true) {
              this.count--;
              this.getItems(
                this.activeRow.inventoryCode,
                this.activeRow.codigo
              );
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          (error) => {
            this._utilsService.showNotification(2, error);
            console.log(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    } else {
      this._utilsService.showNotification(
        2,
        "No se ingreso la serie correctamente"
      );
    }
  }

  // UPDATE
  update() {
    this.isDisabled = true;
    this.loading = true;

    this.inventory.inventoryBusinessCode != null
      ? (this.inventory.inventoryBusinessCode =
          this.inventory.inventoryBusinessCode.replace(/'/g, "&apos;"))
      : "";
    this.inventory.inventorySerialFlag == true
      ? (this.inventory.inventorySerialFlag = 1)
      : (this.inventory.inventorySerialFlag = 0);

    this.inventory.storeCode = this.storeCode;
    this.inventory.userCode = this.userCode;

    this._inventoryService
      .update(this.inventory)
      .then(
        async (response) => {
          if (response.result == true) {
            document.getElementById("image_inventory").style.display = "block";
            this.isEdit = false;
            this.getAll(this.storeCode);
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        (error) => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      )
      .finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
  }

  updateActive($event) {
    $event.preventDefault();
    console.log("Active Row", this.activeRow);

    let jsonUpdate = {
      inventoryCode: this.activeRow.inventoryCode,
      inventoryStatus: 1,
      inventoryDeleted: 0,
      storeCode: this.storeCode,
      userCode: this.userCode
    };

    console.log("inventory -> ", jsonUpdate);

    this._inventoryService
      .update(jsonUpdate)
      .then(
        async (response) => {
          if (response.result == true) {
            this.isEdit = false;
            this.getAll(this.storeCode);
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        (error) => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      )
      .finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });

    //this.update();

    // this.isDisabled = true;
    // this.loading = true;

    // this.inventory.inventoryBusinessCode != null ? this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/'/g, '&apos;') : '';
    // this.inventory.inventorySerialFlag == true ? this.inventory.inventorySerialFlag = 1 : this.inventory.inventorySerialFlag = 0;

    // this._inventoryService.update(this.inventory).then(
    //   async (response) => {
    //     if (response.result == true) {
    //       this.isEdit = false;
    //       this.getAll(this.storeCode);
    //       this.cleanModel();
    //       this._utilsService.showNotification(1, response.message);
    //     } else {
    //       this._utilsService.showNotification(3, response.errorMessage);
    //     }
    //   },
    //   error => {
    //     this._utilsService.showNotification(2, error);
    //     console.log(error);
    //   }
    // ).finally(() => {
    //   this.isDisabled = false;
    //   this.loading = false;
    // });
  }

  updateInactive($event) {
    $event.preventDefault();
    console.log("Inactive Row", this.activeRow);

    let jsonUpdate = {
      inventoryCode: this.activeRow.inventoryCode,
      inventoryStatus: 2,
      inventoryDeleted: 0,
    };

    console.log("inventory -> ", jsonUpdate);

    this._inventoryService
      .update(jsonUpdate)
      .then(
        async (response) => {
          if (response.result == true) {
            this.isEdit = false;
            this.getAll(this.storeCode);
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        (error) => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      )
      .finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });

    //this.update();

    // this.isDisabled = true;
    // this.loading = true;

    // this.inventory.inventoryBusinessCode != null ? this.inventory.inventoryBusinessCode = this.inventory.inventoryBusinessCode.replace(/'/g, '&apos;') : '';
    // this.inventory.inventorySerialFlag == true ? this.inventory.inventorySerialFlag = 1 : this.inventory.inventorySerialFlag = 0;

    // this._inventoryService.update(this.inventory).then(
    //   async (response) => {
    //     if (response.result == true) {
    //       this.isEdit = false;
    //       this.getAll(this.storeCode);
    //       this.cleanModel();
    //       this._utilsService.showNotification(1, response.message);
    //     } else {
    //       this._utilsService.showNotification(3, response.errorMessage);
    //     }
    //   },
    //   error => {
    //     this._utilsService.showNotification(2, error);
    //     console.log(error);
    //   }
    // ).finally(() => {
    //   this.isDisabled = false;
    //   this.loading = false;
    // });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.inventory.inventoryCode = this.activeRow.inventoryCode;
    this.inventory.inventoryStatus = 0;
    this.inventory.inventoryDeleted = 1;

    swal
      .fire({
        title: "Esta seguro",
        text: "El ítem se elminara definitivamte!",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        confirmButtonText: "Si, eliminar!",
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.update();
          swal.fire({
            title: "Enviado!",
            text: "Se ha mandado la solicitud.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.inventory.inventoryCode = this.activeRow.inventoryCode;
    this.inventory.inventoryBusinessCode = this.activeRow.inventoryBusinessCode;
    this.inventory.inventoryCompany = this.activeRow.inventoryCompany;
    this.inventory.inventoryName = this.activeRow.inventoryName;
    this.inventory.inventoryPriceCost = this.activeRow.inventoryPriceCost;
    this.inventory.inventoryPrice = this.activeRow.inventoryPrice;
    this.inventory.inventoryPriceDiscount =
      this.activeRow.inventoryPriceDiscount;
    this.inventory.inventorySalePrice = this.activeRow.inventorySalePrice;
    this.inventory.inventoryMinPrice = this.activeRow.inventoryMinPrice;
    this.inventory.inventoryQuantity = this.activeRow.inventoryQuantity;
    this.inventory.inventoryMin = this.activeRow.inventoryMin;
    this.inventory.inventoryMax = this.activeRow.inventoryMax;
    this.inventory.inventoryPurchaseDetail =
      this.activeRow.inventoryPurchaseDetail;
    this.inventory.inventoryProduct = this.activeRow.inventoryProduct;
    this.inventory.inventoryDescription = this.activeRow.inventoryDescription;
    this.inventory.inventorySerialFlag = this.activeRow.inventorySerialFlag;

    this.inventory.inventorySerialFlag == 1
      ? (this.inventory.inventorySerialFlag = true)
      : (this.inventory.inventorySerialFlag = false);

    const _selectedItem = this.productList.find(
      (x) => x.id == this.activeRow.inventoryProduct
    );
    this.getSelectSettingProduct(_selectedItem);

    this.inventoryAux = this.activeRow;

    document.getElementById("image_inventory").style.display = "none";
  }

  ///////////////////////SAVE MULTI STORE///////////////////////
  getStoreByCompany(companyCode) {
    return this._storeService.getAllByCompany(companyCode).then(
      (response) => {
        if (response.result) {
          for (const e of response.records) {
            this.storeList.push({ id: e.storeCode, itemName: e.storeName });
          }
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onItemSelectStore(item: any) {
    this.storeListSelect.push({ inventoryStore: item.id });
    this.disabledMultiStore = false;
    console.log(item);
    this.storeInv = item.id;
    this.storeInvN = item.itemName;
    console.log(this.storeInv);
  }

  onSelectAllStore() {
    this.storeListSelect = [];
    this.storeList.forEach((x) => {
      this.storeListSelect.push({ inventoryStore: x.id });
    });
  }

  onDeItemSelectStore(item: any) {
    const find = this.storeListSelect.findIndex(
      (x) => x.inventoryStore === item.id
    );
    this.storeListSelect.splice(find, 1);

    this.storeListSelect.length == 0 ? (this.disabledMultiStore = true) : "";
  }

  onDeSelectAllStore() {
    this.storeListSelect = [];
    this.selectedItemsStore = null;
  }

  disabledMultiStore = false;

  async inventoryStore(){
    let storeSearch;
    storeSearch = this.storeInv == null ? storeSearch = this.storeCode : this.storeInv;

    console.log(storeSearch);

    this.loading = true;
    await this.getAll(storeSearch);
    this.storeName = this.storeInvN == null ? this.storeName = this.storeName : this.storeInvN;
    this.loading = false;
  }

  // MODAL
  closeResult = "";
  open(content, option) {
    this.disabledMultiStore = true;

    //Retornar Campos que no se pueden editar
    this.inventory.inventoryBusinessCode =
      this.inventoryAux.inventoryBusinessCode;
    this.inventory.inventoryName = this.inventoryAux.inventoryName;
    this.inventory.inventoryDescription =
      this.inventoryAux.inventoryDescription;
    this.inventory.inventoryQuantity = this.inventoryAux.inventoryQuantity;
    this.inventory.inventoryMin = this.inventoryAux.inventoryMin;
    this.inventory.inventoryMax = this.inventoryAux.inventoryMax;
    this.inventory.inventoryProduct = this.inventoryAux.inventoryProduct;
    const _selectedItem = this.productList.find(
      (x) => x.id == this.inventoryAux.inventoryProduct
    );
    this.getSelectSettingProduct(_selectedItem);
    this.inventoryAux.inventorySerialFlag == 1
      ? (this.inventory.inventorySerialFlag = true)
      : (this.inventory.inventorySerialFlag = false);

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        async (result) => {
          this.closeResult = `Closed with: ${result}`;

          if (option == 1) {
            const json = {
              inventoryStore: this.storeListSelect,
              inventoryBusinessCode: this.inventory.inventoryBusinessCode,
              inventoryPrice: this.inventory.inventoryPrice,
              inventoryMinPrice: this.inventory.inventoryMinPrice,
              inventoryPriceCost: this.inventory.inventoryPriceCost,
              inventoryPriceDiscount: this.inventory.inventoryPriceDiscount,
              inventorySalePrice: this.inventory.inventorySalePrice,
              storeCode: this.storeCode,
              userCode: this.userCode,
              inventoryCode: this.inventoryAux.inventoryCode,
            };

            /// UPDATE
            this.loading = true;
            this._inventoryService
              .update(json)
              .then(
                (response) => {
                  if (response.result) {
                    this.isEdit = false;
                    this.cleanModel();
                    this._utilsService.showNotification(1, response.message);
                  } else {
                    this._utilsService.showNotification(3, response.message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              )
              .finally(() => {
                this.loading = false;
              });
          } else if (option == 2) {
            let rankList = new Array();
            this.pricesList.forEach((e) => {
              if (e.minUnd && e.maxUnd && e.price) {
                rankList.push({
                  inventoryPriceRankFrom: e.minUnd,
                  inventoryPriceRankTo: e.maxUnd,
                  inventoryPriceRankPrice: e.price,
                });
              }
            });

            const json = {
              inventoryPriceRank: rankList,
              inventoryStore: this.storeListSelect,
              inventoryBusinessCode: this.activeRow.inventoryBusinessCode,
            };

            // UPDATE
            this.loading = true;
            this._inventoryService
              .insertPrice(json)
              .then(
                (response) => {
                  if (response.result) {
                    this.cleanModel();
                    this.viewBeginning();
                    this._utilsService.showNotification(1, response.message);
                  } else {
                    this._utilsService.showNotification(3, response.message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              )
              .finally(() => {
                this.loading = false;
              });
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.disabledMultiStore = false;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ///////////////////////COMPLEMENT///////////////////////
  //  VIEWS
  viewBeginning() {
    this.isViewBeginning = true;
    this.isViewPrices = false;
    this.isViewSeries = false;

    //this.getAll(this.storeCode);
    this.cleanModel();
  }

  viewPrices($event) {
    $event.preventDefault();
    this.isViewBeginning = false;
    this.isViewSeries = false;
    this.isViewPrices = true;

    this.storeListSelect = [];
    this.selectedItemsStore = [];
  }

  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
    document.getElementById("image_inventory").style.display = "block";
  }

  // CLEAR MODAL
  cleanModel() {
    this.isEdit = false;
    this.isDisabled = false;
    this.itemList = [];
    this.pricesList = [];
    this.inventory = {} as Inventory;
    this.inventoryAux = {} as Inventory;
    this.inventory.inventoryPurchaseDetail = 0;
    this.inventory.inventoryPrice = 0;
    this.inventory.inventoryPriceCost = 0;
    this.inventory.inventorySalePrice = 0;
    this.inventory.inventoryPriceDiscount = 0;
    this.inventory.inventoryQuantity = 0;
    this.inventory.inventoryMinPrice = 0;
    this.inventory.inventoryMin = 0;
    this.inventory.inventoryMax = 0;
    this.state = {};

    this.selectedItemsProduct = null;
    this.selectedItemsStore = [];
    this.storeListSelect = [];

    this.getAll(this.storeCode);
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach((element) => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Reporte");
    xlsx.writeFile(wb, "reporte-inventario.xlsx");
  }

  // DESCARGAR EXCEL SERIES
  async downloadSeriesToExcel() {
    const checkList = this.itemList;
    const exportFile = new Array<any>();

    checkList.forEach((element) => {
      let json = {};
      Object.keys(this.settingsSerial.columns).forEach((item, index, arr) => {
        if (this.settingsSerial.columns[item].type === "number") {
          json[this.settingsSerial.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsSerial.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Reporte");
    xlsx.writeFile(wb, "reporte-inventario-series.xlsx");
  }

  // FUNCTION FOR IMAGENS
  public state: any = {};
  addFile(e) {
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  //verificar si esta vacio el state
  isObjectEmpty = function (state) {
    return Object.keys(state).length === 0;
  };
}
