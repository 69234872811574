<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm  d-print-none">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/account-statement">Estado de cuenta</a>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="row">

                <div class="col-md-12 d-print-none">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-8">
                            <label>Cuenta Bancaria:</label>                            
                            <select name="bankAccountList" aria-controls="bankAccountList" class="form-control"
                                [(ngModel)]="bankAccountSelect" (change)="bankAccountChange($event)">
                                <option [value]="bankAccount.id_account" *ngFor="let bankAccount of bankAccountList">{{bankAccount.name}} | {{bankAccount.number}}</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group pt-2">
                              <button class="btn btn-info btn-sale" style="width: 100%;"
                                (click)="getAll()">Ver transacciones</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="isBankAccountSelect">
                    <div id="printHeader" class="card">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-11 font-weight-bold">
                                    Detalle de la cuenta bancaria
                                </div>
                                <div id="print-none-button" class="col-4 text-right d-print-none" *ngIf="isViewBeginning">
                                    <a class="btn btn-warning btn-block btn-gen" role="button" (click)="printHistory($event)">
                                        <i class="now-ui-icons files_paper"></i> Imprimir
                                    </a>
                                </div>
                                <div class="col-sm-11">
                                    <div class="row payRegisterList" *ngFor="let item of bankAccountObjectSelect">
                                        <div class="col-md-3 itemBlock">
                                            <label>Nombre</label>
                                            <p>{{ item.name }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>No. Cuenta</label>
                                            <p>{{ item.number }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Tipo</label>
                                            <p>{{ item.type_desc }} </p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <label>Banco</label>
                                            <p>{{ item.bank }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <p class="text-info">Saldo</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <p>{{ item.currency }}. {{ item.amount | number:'1.2-2' }}</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <p class="text-info">Última transacción</p>
                                        </div>
                                        <div class="col-md-3 itemBlock">
                                            <p>{{  item.date_update ? (item.date_update | date:'dd-MM-yyyy') : '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 d-print-none" *ngIf="isViewNewRegister">
                    <form #formulario="ngForm">
                        <div class="card">
                            <div class="card-header ">
                                <div class="row">
                                    <div class="col-10">
                                        <b class="card-title" *ngIf="!isEdit">Registrar transferencia</b>
                                        <b class="card-title text-warning" *ngIf="isEdit">Modificar  transferencia</b>
                                    </div>
                                    <div class="col-2 float-right pr-4" style="text-align: end;"> 
                                        <a class="btn btn-danger btn-icon btn-round "
                                            (click)="viewNewRegister($event)"><i class="fas fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>
                                            Método de pago
                                            <span class="text-danger" *ngIf="validate(1, 'method')">{{requiredText}}</span>
                                        </label>
                                        <select name="method" class="form-control form-control-sm"
                                            [(ngModel)]="transaction.method">
                                            <option [value]="method.id_paymentMethod" *ngFor="let method of methodList" required>{{method.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleOrRequiered('document')">
                                        <label>
                                            No. Documento
                                            <span class="text-danger" *ngIf="validate(1, 'document')">{{requiredText}}</span>
                                        </label>
                                        <input name="document" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="transaction.document" required="isVisibleOrRequiered('document')" />
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleOrRequiered('reference')">
                                        <label>
                                            No. Autorización
                                            <span class="text-danger" *ngIf="validate(1, 'reference')">{{requiredText}}</span>
                                        </label>
                                        <input name="reference" type="text" class="form-control form-control-sm"
                                            [(ngModel)]="transaction.reference" required="isVisibleOrRequiered('reference')" />
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleOrRequiered('bank')">
                                        <label>
                                            Banco
                                            <span class="text-danger" *ngIf="validate(1, 'bank')">{{requiredText}}</span>
                                        </label>
                                        <select name="bank" aria-controls="bank" class="form-control"
                                            [(ngModel)]="transaction.bank"  required="isVisibleOrRequiered('bank')">
                                            <option [value]="bank.id_bank" *ngFor="let bank of bankList">{{bank.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label>
                                            Tipo
                                            <span class="text-danger" *ngIf="validate(1, 'type')">{{requiredText}}</span>
                                        </label>
                                        <select name="type" class="form-control form-control-sm"
                                            [(ngModel)]="transaction.type" required>
                                            <option value="CREDIT">Crédito</option>
                                            <option value="DEBIT">Débito</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label>
                                            Monto
                                            <span class="text-danger" *ngIf="validate(1, 'amount')">{{requiredText}}</span>
                                        </label>
                                        <mat-form-field style="display: flow !important;">
                                            <span matPrefix class="pr-1">{{bankAccountObjectSelect[0]?.currency}}</span>
                                            <input matInput name="amount" type="number" class="form-control form-control-sm" style="display: unset !important;width: calc(100% - 15px) !important;"
                                                [(ngModel)]="transaction.amount" required/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <label>
                                            Fecha transacción
                                            <span class="text-danger" *ngIf="validate(1, 'date')">{{requiredText}}</span>
                                        </label>
                                        <input name="date" type="date" class="form-control" [(ngModel)]="transaction.date" required />
                                    </div>
                                    <div class="col-md-10">
                                        <label>
                                            Observaciones
                                        </label>
                                        <textarea name="observation" class="form-control form-control-sm" cols="10" rows="2"
                                            [(ngModel)]="transaction.observation">
                                        </textarea>
                                    </div>
                                    <div class="col-md-2 text-left">
                                        <button type="submit" class="btn btn-info btn-sm" (click)="save()"
                                            style="padding-left: 2.5rem; padding-right: 2.5rem;" [disabled]="formulario.invalid">
                                            REGISTRAR TRANSACCIÓN ✔ </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="col-md-12" *ngIf="isViewBeginning">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-8">
                                  <h4 class="card-title">Listado de transacciones</h4>
                                  <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                                  </h6>
                                </div>
                                <div class="col-md-4">
                                    <div class="row p-2">
                                        <button class="btn btn-info btn-sale col-md-5" style="width: 100%;"
                                            (click)="viewNewRegister($event)">Agregar transacción</button>
                                        <button class="btn btn-success btn-sale col-md-5" style="width: 100%;" (click)="downloadToExcel()"
                                            [disabled]="isDisabled">
                                            Descargar Excel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Show
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm"
                                                    (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ngx-datatable id="printDetail" class="bootstrap selection-cell" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
                                    [limit]="entries != -1 ? entries:undefined" (activate)="onActivate($event)"
                                    [rows]="temp">
                                    <ngx-datatable-column name="Fecha" prop="date_transaction">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <p  class="text-center">
                                                {{ row.date_create | date:'dd-MM-yyyy' }}<br>
                                                {{ row.date_create | date:'h:mm a' }}
                                            </p>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Documento" prop="document"></ngx-datatable-column>
                                    <ngx-datatable-column name="Referencia" prop="autorization"></ngx-datatable-column>
                                    <ngx-datatable-column name="Tipo" prop="type_desc"></ngx-datatable-column>
                                    <ngx-datatable-column name="Banco" prop="bank"></ngx-datatable-column>
                                    <ngx-datatable-column name="Monto" prop="amount">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <strong *ngIf="row.type == 'DEBIT'" class="text-success">{{ row.currency }}. {{ row.amount | number:'1.2-2' }}</strong>
                                            <strong *ngIf="row.type == 'CREDIT'" class="text-danger">{{ row.currency }}. {{ row.amount | number:'1.2-2' }}</strong>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Método" prop="method"></ngx-datatable-column>
                                    <ngx-datatable-column name="Observaciones" prop="observation"></ngx-datatable-column>
                                    <ngx-datatable-column></ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>