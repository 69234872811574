export class Transaction {
  constructor(
    public method: string,
    public document: string,
    public reference: string,
    public bank: number,
    public type: string,
    public amount: number,
    public date: string,
    public observation: string
  ){}
}


export const DocumentSettings = {
  columns: {
    date_transaction: {
      title: 'Fecha'
    },
    document: {
      title: 'Documento', 
      type: 'number'
    },
    autorization: {
      title: 'Referencia', 
      type: 'number'
    },
    type_desc: {
      title: 'Tipo'   
    },
    bank: {
      title: 'Banco'       
    },
    currency: {
      title: 'Moneda'       
    },
    amount: {
      title: 'Monto',
      type: 'number'        
    },
    method: {
      title: 'Método'       
    },
    observation: {
      title: 'Observaciones'
    }
  }
}


export const inputsRequiereSettings = {
  'CHQE': ['document', 'bank'],
  'DEPO': ['document', 'reference', 'bank'],
  'EFEC': [],
  'TDC': ['reference', 'bank'],
  'TDD': ['reference', 'bank'],
  'TRAN': ['reference', 'bank'],
  'TACH': ['reference', 'bank']
}