import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import swal from "sweetalert2";
import { BankAccount, BankAccountModel } from 'src/app/models/bankAccount';
import { BankService } from 'src/app/services/bank.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['../../../app.component.scss'],
  providers: [UtilsService, BankService]
})

export class BankAccountComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;

  public companyCode: number;
  public bankAccountList = new Array();
  public bankAccount = {} as BankAccount;
  public bankAccountModel = {} as BankAccountModel;
  public typeAccountList: any[] = new Array();
  public bankList: any[] = new Array();
  public currencyList: any[] = new Array();

  public isDisabled: boolean = false;
  public loading: boolean = false;
  public isEdit: boolean = false;

  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public requiredText: any = `*Campo requerido`;

  constructor(
    private _utilsService: UtilsService,
    private _bankService: BankService,
    private router: Router
  ) { }

  ngOnInit() {
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.getBankAll();
    this.getCurrencyAll();
    this.getAll();
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.bankAccountList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  validate(type: number, name: string): boolean {
    switch (type) {
      case 1: 
        return this.formulario?.controls[name]?.errors
          && this.formulario?.controls[name]?.touched;
      case 2: 
        return this.formulario?.controls[name]?.value < 0;
    }
  }
  
  getAll() {
    this.loading = true;

    return this._bankService.getBankAccountAll(this.companyCode).then(
      response => {
        if (response.result) {
          this.bankAccountList = response.records;
          this.temp = this.bankAccountList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getBankAll() {
    this.loading = true;

    return this._bankService.getBankAll().then(
      response => {
        if (response.result) {
          this.bankList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getCurrencyAll() {
    this.loading = true;

    return this._bankService.getCurrencyAll().then(
      response => {
        if (response.result) {
          this.currencyList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  save () {
    if (this.formulario.valid) {
      this.loading = true;
      this.isDisabled = true;

      this._bankService.insertBankAccount(this.companyCode, this.bankAccountModel).then(
        async (response) => {
          if (response.result == true) {
            this.cleanModel();
            this.getAll();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
          this.loading = false;
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Debe completar los campos obligatorios');
    }
  }

  update() {
    if (this.formulario.valid) {
      this.loading = true;
      this.isDisabled = true;

      this._bankService.updateBankAccount(this.bankAccountModel).then(
        async (response) => {
          if (response.result == true) {
            this.cleanModel();
            this.getAll();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
          this.loading = false;
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    }else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Debe completar los campos obligatorios');
    }
  }

  loadFuntion($event) {
    $event.preventDefault();
    localStorage.setItem("idBankAccount", this.activeRow.id_account);
    this.router.navigate(['/account-statement']);
  }

  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;
    this.bankAccountModel.id_account = this.activeRow.id_account;
    this.bankAccountModel.name = this.activeRow.name;
    this.bankAccountModel.number = this.activeRow.number;
    this.bankAccountModel.type = this.activeRow.type;
    this.bankAccountModel.bank = this.activeRow.id_bank;
    this.bankAccountModel.currency = this.activeRow.id_currency;

  }

  deleteFunction($event) {
    $event.preventDefault();
    this.loading = true;
    this.isDisabled = true;

    swal.fire({
      title: "¿Está  seguro? ",
      text: "La cuenta bancaria se eliminará definitivamente",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Sí, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this._bankService.deleteBankAccount(this.activeRow.id_account).then(
          async (response) => {
            if (response.result == true) {
              this.cleanModel();
              this.getAll();
              
              swal.fire({
                title: "Enviado!",
                text: "Se ha mandado la solicitud.",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false
              });
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this._utilsService.showNotification(2, error);
            console.log(error);
            this.loading = false;
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
    });
  }

  cleanModel() {
    this.isEdit = false;
    this.isDisabled = false;
    this.bankAccount = {} as BankAccount;
    this.formulario.resetForm();
  }
}
