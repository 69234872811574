import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BankAccountModel } from 'src/app/models/bankAccount';
import { Transaction } from 'src/app/models/transaction';

interface HttpDeleteOptions {
  body?: any,
  headers?: any
}

@Injectable()
export class BankService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.accountingApiUrl + "erpconta-";
  }

  getBankAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "bank", { headers: headers })
      .toPromise();
  }
  
  getTypeAccountAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "typeAccount", { headers: headers })
      .toPromise();
  }
  
  getCurrencyAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "currency", { headers: headers })
      .toPromise();
  }

  getMethodAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "payment/method", { headers: headers })
      .toPromise();
  }

  getBankAccountAll(companyCode: number): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "account/" + companyCode, { headers: headers })
      .toPromise();
  }

  getBankAccountOneByAccount(companyCode: number, idAccount: number): Promise<any> {
    let params = JSON.stringify({ companyCode, accountCode: idAccount });
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .post(this.url + "account/list", params, { headers: headers })
      .toPromise();
  }

  getTransactionByAccount(accountCode: number): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .get(this.url + "transaction/" + accountCode, { headers: headers })
      .toPromise();
  }

  insertBankAccount(companyCode: number, bankAccount: BankAccountModel): Promise<any> {
    let params = JSON.stringify({
      companyCode,
      ...bankAccount
    });
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url +  "account", params, { headers: headers }).toPromise();
  }

  updateBankAccount(bankAccount: BankAccountModel): Promise<any> {
    let params = JSON.stringify({
      ...bankAccount
    });
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + 'account', params, { headers: headers }).toPromise();
  }

  deleteBankAccount(id_account: number): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    const options: HttpDeleteOptions = {
      body: { id_account },
      headers: headers
    }

    return this._http.delete(this.url + 'account', options).toPromise();
  }

  insertTransaction(balanceCode: number, transaction: Transaction): Promise<any> {
    let params = JSON.stringify({
      balanceCode,
      ...transaction,
      date: `${transaction.date} 00:00:00`
    });
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url +  "transaction", params, { headers: headers }).toPromise();
  }
}
