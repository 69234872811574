<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/inventory">Inventario</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- MODAL STORES-->
    <ng-template #content let-modal>
        <div class="modal-body">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <h3>Seleccione las Sucursales</h3>                    
                    <div class="form-group">
                        <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                            [settings]="dropdownSettingsStore" (onSelect)="onItemSelectStore($event)" (onSelectAll)="onSelectAllStore()"
                            (onDeSelect)="onDeItemSelectStore($event)" (onDeSelectAll)="onDeSelectAllStore()">
                        </angular2-multiselect>
                    </div>
                    <label class="text-info">*Los campos monetarios seran actualizados en las sucursales</label>
                </div>
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')"
                    [disabled]="disabled">Actualizar</button>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- INPUT INVENTORY -->
    <div class="row" *ngIf="isViewBeginning && userRole != 54">
        <div class="col-md-12">
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-7">
                            <h4 class="card-title" *ngIf="!isEdit">
                                Nuevo Ítem</h4>
                            <h4 class="card-title text-warning" *ngIf="isEdit">Modificar Ítem</h4>
                        </div>
                        <div class="col-4 text-center">
                            <button *ngIf="store.storeMain == 1" class="btn btn-success btn-sale"
                                (click)="open(content, 1)" [disabled]="inventory.inventoryBusinessCode == undefined || isDisabled">
                                Modificar en Sucursales
                            </button>
                        </div>
                        <div class="col-1" *ngIf="isEdit">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="cancelUpdate($event)"><i
                                    class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="card-body ">
                    <div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>*Categoría</label>
                                <div class="form-group">
                                    <angular2-multiselect [data]="productList" [(ngModel)]="selectedItemsProduct"
                                        [settings]="dropdownSettingsProduct" (onSelect)="onItemSelectProduct($event)">
                                    </angular2-multiselect>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Código</label>
                                <div class="form-group">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="inventory.inventoryBusinessCode" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <label>*Nombre</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="inventory.inventoryName"
                                        autocomplete="off" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Descripción</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="inventory.inventoryDescription"
                                        autocomplete="off" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>Stock</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryQuantity"
                                        autocomplete="off" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>Stock Minimo</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryMin"
                                        autocomplete="off" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>Stock Maximo</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryMax"
                                        autocomplete="off" [disabled]="disabled"/>
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf="userRole != 54">
                                <label>*Costo</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryPriceCost"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>*Precio de Venta</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryPrice"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>*Precio Min. de Venta</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventoryMinPrice"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>Precio con descuento</label>
                                <div class="form-group">
                                    <input type="number" class="form-control"
                                        [(ngModel)]="inventory.inventoryPriceDiscount" autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label>Precio de promoción</label>
                                <div class="form-group">
                                    <input type="number" class="form-control" [(ngModel)]="inventory.inventorySalePrice"
                                        autocomplete="off" />
                                </div>
                            </div>

                            <!-- *ngIf="companyCode == 22" -->
                            
                            <div class="col-md-2" id="image_inventory">
                                <label>Cargar imagen</label>
                                <div class="form-group">
                                    <!-- <span class="btn btn-block btn-round btn-file" style="margin: 0;"> -->
                                    <span class="btn btn-block btn-round btn-file" [ngClass]="{'btn-gray' : isObjectEmpty(state), 'btn-success' : !isObjectEmpty(state)}" style="margin: 0;">
                                        <span class="fileinput-new">JPG/PNG</span>
                                        <input type="file" (change)="addFile($event)" />
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <label>No. de Series</label>
                                <div class="form-group">
                                    <bSwitch [switch-on-color]="'success'" [(ngModel)]="inventory.inventorySerialFlag"
                                        switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                        switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                    </bSwitch>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="statistics">
                                    <button class="btn btn-block btn-lg mt-10 btn-info" (click)="save()"
                                        *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-lg mt-10" (click)="update()"
                                        *ngIf="isEdit" [disabled]="isDisabled">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END INPUT INVENTORY -->

    <div class="row" *ngIf="isInventoryView">
        <div class="col-md-6">
            <div class="card ">
                <div class="card-header ">
                    <div class="card-body ">
                        <h3>Seleccione la Sucursales</h3>                    
                        <div class="form-group">
                            <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                                [settings]="dropdownSettingsStores" (onSelect)="onItemSelectStore($event)" (onSelectAll)="onSelectAllStore()"
                                (onDeSelect)="onDeItemSelectStore($event)" (onDeSelectAll)="onDeSelectAllStore()">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-12">
                            <div class="statistics">
                                <button class="btn btn-block btn-lg mt-10 btn-info" (click)="inventoryStore()">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TABLE ALL -->
    <div class="row" *ngIf="isViewBeginning">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">
                                <b>{{storeName}}</b>
                                <br>Listado de Ítems.
                            </h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="getItemsStore()"
                                [disabled]="isDisabled">
                                Descargar Excel Series
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- [columnMode]="'ColumnMode.flex | .force | .standard'" [flexGrow]="3" [width]="300" [minWidth]="200" [maxWidth]="200"-->
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [colWidth]="'auto'"
                            [limit]="entries != -1 ? entries:undefined" [scrollbarH]="true" [rows]="temp"
                            (activate)="onActivate($event)">
                            <ngx-datatable-column name="codigo" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="categoría" prop="productName" [minWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="nombre" [minWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="venta" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="stock" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="costo" [maxWidth]="200" *ngIf="userRole != 54"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado" prop="estadoTexto" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column [minWidth]="200" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        *ngIf="row.inventoryStatus == 1"
                                        (click)="viewFunction($event)" ngbTooltip="Ver" placement="left"><i class="fas fa-eye"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-gray btn-icon btn-sm like"
                                        *ngIf="row.inventoryStatus == 1 && userRole != 54"
                                        (click)="viewPricesList($event)" ngbTooltip="Rango de Precios" placement="left">
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-warning btn-icon btn-sm like"
                                        *ngIf="row.inventorySerialFlag == 1"
                                        (click)="viewSeries($event, null, row.codigo)" ngbTooltip="Series" placement="left"><i
                                            class="fas fa-qrcode"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        *ngIf="row.inventoryStatus == 1 && userRole != 54"
                                        (click)="editFunction($event)" ngbTooltip="Editar" placement="left"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm like"
                                        *ngIf="row.inventoryStatus == 2 && userRole != 54"
                                        (click)="updateActive($event)" ngbTooltip="Activar Articulo" placement="left"><i
                                            class="fas fa-check-circle"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm like"
                                        *ngIf="row.inventoryStatus == 1 && userRole != 54"
                                        (click)="updateInactive($event)" ngbTooltip="Inactivar Articulo" placement="left"><i
                                            class="fas fa-minus-circle"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        *ngIf="userRole != 54"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar" placement="left"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->

    <!-- TABLE PARAMS PRICE -->
    <div class="row" *ngIf="isViewPrices">
        <!-- <div class="col-sm-12 text-right">
            <button *ngIf="store.storeMain == 1" class="btn btn-success btn-sale" style="margin-bottom: 0.5rem !important;"
                (click)="open(content, 2)" [disabled]="inventory.inventoryBusinessCode == undefined || isDisabled">
                Modificar en Sucursales
            </button>
        </div> -->
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="row align-middle">
                        <div class="col-sm-7">
                            <strong style="font-size: 1.5rem;">Rango de Precios</strong>
                            <br>
                            <span>Código: {{inventory.inventoryBusinessCode}} | {{inventory.inventoryName}}</span>
                        </div>
                        <div class="col-sm-4">
                            <button *ngIf="store.storeMain == 1" class="btn btn-success btn-sale" style="width: 90%; margin-bottom: 0.5rem !important;"
                                (click)="open(content, 2)">
                                Guardar
                            </button>                        
                        </div>
                        <div class="col-sm-1 text-right">
                            <a class="btn btn-round btn-warning btn-icon btn-sm remove"
                                (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="text-center" style="font-weight: bold;">
                                            <td>No.</td>
                                            <td>Desde Unid.</td>
                                            <td>Hasta Unid.</td>
                                            <td>Precio U. Q</td>
                                            <!-- <td>Acción</td> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let e of pricesList, let i=index" class="text-center">
                                            <td>{{e.no}}</td>
                                            <td><input type="number" class="form-control input-sm"
                                                    [(ngModel)]="e.minUnd" />
                                            <td><input type="number" class="form-control input-sm"
                                                    [(ngModel)]="e.maxUnd" /></td>
                                            <td><input type="number" class="form-control input-sm"
                                                    [(ngModel)]="e.price" /></td>
                                            <!-- <td>
                                                <button class="btn btn-success sm" [disabled]="isDisabled"
                                                    (click)="savePrice(e.no, e.priceCode, e.inventoryCode, e.minUnd, e.maxUnd, e.price)"
                                                    ngbTooltip="Guardar" placement="left">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TABLE ITEM -->
    <div class="row" *ngIf="isViewSeries">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">                        
                        <div class="col-sm-12 text-right">
                            <a class="btn btn-round btn-warning btn-icon btn-sm remove"
                                (click)="viewBeginning($event)"><i class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-11">
                            <h4 class="card-title">Listado de Ítems</h4>
                            <h6 class="text-danger" *ngIf="count > 0">Items pendientes de no. serial: {{count}}</h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadSeriesToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel Series
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="codigo"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Serie"></ngx-datatable-column>
                            <ngx-datatable-column name="CAD"></ngx-datatable-column>
                            <ngx-datatable-column name="Lote"></ngx-datatable-column>
                            <ngx-datatable-column name="Stock"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <div class="input-group flex-nowrap" *ngIf="row.serie == null">
                                        <a (click)="saveSerie($event)">
                                            <span class="input-group-text" id="addon-wrapping">✔</span>
                                        </a>
                                        <input type="text" class="form-control" placeholder="Serie"
                                            [(ngModel)]="row.inventorySerialFlag" aria-label="Username"
                                            aria-describedby="addon-wrapping"
                                            (click)="getPreviousValue(row.inventorySerialFlag)" />
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>