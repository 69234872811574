import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as xlsx from 'xlsx';
import { Transaction, DocumentSettings, inputsRequiereSettings } from 'src/app/models/transaction';
import { Company } from "src/app/models/company";
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from 'src/app/services/print.service';
import { BankService } from 'src/app/services/bank.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss'],
  providers: [UtilsService, PrintService, BankService, CompanyService]
})

export class AccountStatementComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;
  public companyCode: number;
  public transaction = {} as Transaction;
  private company = {} as Company;
  public settings = DocumentSettings;
  public requieres = inputsRequiereSettings;
  public bankAccountList = new Array();
  public bankAccountSelect: number;
  public bankAccountObjectSelect: any[];
  public isDisabled: boolean;
  public isBankAccountSelect: boolean;
  public isViewBeginning: boolean;
  public isViewNewRegister: boolean;
  public loading: boolean = false;
  public transacctiontList: any[];
  public bankList: any[] = new Array();
  public methodList: any[] = new Array();
  public temp = [];
  public entries: number = 10;
  public activeRow: any;
  public requiredText: any = `*Campo requerido`;
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;

  constructor(
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _bankService: BankService,
    private _companyService: CompanyService
  ) { }

  async ngOnInit() {
    const _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.isDisabled = true;
    this.getMethodAll();
    this.getBankAll();
    await this.getCompany(this.companyCode);
    await this.getBankAccount(this.companyCode);
    if (this.bankAccountSelect) this.getAll();
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  bankAccountChange($event) {
    if($event.target.value) {
      this.bankAccountObjectSelect = this.bankAccountList.filter(item => item.id_account == $event.target.value)
      this.isBankAccountSelect = true && this.bankAccountObjectSelect.length > 0;
      this.cleanModel();
    } else {
      this.isBankAccountSelect = false;
      this.bankAccountObjectSelect = [];
    }
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.transacctiontList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  isVisibleOrRequiered(inputName) {
    const method = this.methodList.find(item => item.id_paymentMethod === Number(this.transaction.method));
    console.log(method)

    if(method && method?.code) {
      const requieresList = this.requieres[method?.code];
      console.log(requieresList)
      return requieresList.includes(inputName);
    }

    return false;
  }

  validate(type: number, name: string): boolean {
    switch (type) {
      case 1: 
        return this.formulario?.controls[name]?.errors
          && this.formulario?.controls[name]?.touched;
      case 2: 
        return this.formulario?.controls[name]?.value < 0;
    }
  }

  viewNewRegister($event) {
    $event.preventDefault();
    this.isViewNewRegister = !this.isViewNewRegister;
    this.transaction = {} as Transaction;
    this.isViewNewRegister ? this.formulario?.resetForm() : null;
  }

  cleanModel() {
    this.isViewNewRegister ? this.formulario?.resetForm() : null;
    this.transacctiontList = [];
    this.temp = [];
    this.isViewBeginning = false;
    this.isViewNewRegister = false;
    this.isDisabled = true;
  }

  getBankAll() {
    this.loading = true;

    return this._bankService.getBankAll().then(
      response => {
        if (response.result) {
          this.bankList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getMethodAll() {
    this.loading = true;

    return this._bankService.getMethodAll().then(
      response => {
        if (response.result) {
          this.methodList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
    });
  }
  
  getBankAccount(companyCode, id_account = null) {
    this.loading = true;

    return this._bankService.getBankAccountOneByAccount(companyCode, id_account).then(
      response => {
        if (response.result) {
          this.bankAccountList = response.records;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    ).finally(() => {
      this.loading = false;
      this.bankAccountSelect = Number(localStorage.getItem("idBankAccount"));
      this.bankAccountChange({ target: { value: this.bankAccountSelect }})
    });
  }

  
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAll() {
    this.loading = true;

    return this._bankService.getTransactionByAccount(this.bankAccountSelect).then(
      async response => {
        if (response.result) {
          this.transacctiontList = response.records;
          this.temp = this.transacctiontList;
          this.isViewBeginning = true;
        } else {
          console.log(response.message);
          this.isViewBeginning = false;
        }
      },
      error => {
        console.log(error);
        this.isViewBeginning = false;
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  }

  async printHistory($event){
    $event.preventDefault();
    const headHtml = document.head.innerHTML;
    const headerHtml = document.getElementById("printHeader").outerHTML;
    const detailHtml = document.getElementById("printDetail").outerHTML;

    this._printService.accountStatement(this.company, headHtml, headerHtml, detailHtml)
  }

  save () {
    if (this.formulario.valid) {
      this.loading = true;
      this.isDisabled = true;

      this._bankService.insertTransaction(this.bankAccountSelect, this.transaction).then(
        async (response) => {
          if (response.result == true) {
            this.cleanModel();
            await this.getBankAccount(this.companyCode);
            await this.getAll();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
          this.loading = false;
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Debe completar los campos obligatorios');
    }
  }
  
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item) => {
        if (this.settings.columns[item].type === "") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-transacciones.xlsx');
  }
}
