import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';
import { UserComponent } from './pages/user/user/user.component';
import { PermissionComponent } from './pages/user/permission/permission.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CompanyComponent } from './pages/company/company/company.component';
import { CompanyTypeComponent } from './pages/catalogue/company-type/company-type.component';
import { CompanyPlanComponent } from './pages/catalogue/company-plan/company-plan.component';
import { OwnerComponent } from './pages/company/owner/owner.component';
import { ManagerComponent } from './pages/company/manager/manager.component';
import { StoreComponent } from './pages/company/store/store.component';
import { ProviderComponent } from './pages/provider/provider.component';
import { ClientComponent } from './pages/client/client.component';
import { PurchaseInsertComponent } from './pages/purchase/purchase-insert/purchase-insert.component';
import { ClientCategoryComponent } from './pages/catalogue/client-category/client-category.component';
import { CancellationComponent } from './pages/invoice/cancellation/cancellation.component';
import { ProductComponent } from './pages/catalogue/product/product.component';
import { ProviderCategoryComponent } from './pages/catalogue/provider-category/provider-category.component';
import { PurchaseProcessComponent } from './pages/purchase/purchase-process/purchase-process.component';
import { InventoryComponent } from './pages/inventory/inventory/inventory.component';
import { PurchasePendingComponent } from './pages/purchase/purchase-pending/purchase-pending.component';
import { AccessComponent } from './pages/user/access/access.component';
import { PurchaseViewComponent } from './pages/purchase/purchase-view/purchase-view.component';
import { DescriptionCreditNoteComponent } from './pages/invoice/description/description-credit-note/description-credit-note.component';
import { DescriptionDebitNoteComponent } from './pages/invoice/description/description-debit-note/description-debit-note.component';
import { DescriptionExchangeBillComponent } from './pages/invoice/description/description-exchange-bill/description-exchange-bill.component';
import { DescriptionExportInvoiceComponent } from './pages/invoice/description/description-export-invoice/description-export-invoice.component';
import { InventoryDebitNoteComponent } from './pages/invoice/inventory/inventory-debit-note/inventory-debit-note.component';
import { InventoryExchangeBillComponent } from './pages/invoice/inventory/inventory-exchange-bill/inventory-exchange-bill.component';
import { InventoryExportInvoiceComponent } from './pages/invoice/inventory/inventory-export-invoice/inventory-export-invoice.component';
import { InventoryCreditNoteComponent } from './pages/invoice/inventory/inventory-credit-note/inventory-credit-note.component';
import { PaymentInvoiceComponent } from './pages/accounting/payment-invoice/payment-invoice.component';
import { PaymentRecordsComponent } from './pages/accounting/payment-records/payment-records.component';
import { PaymentPurchaseComponent } from './pages/accounting/payment-purchase/payment-purchase.component';
import { BalanceSheetComponent } from './pages/accounting/balance-sheet/balance-sheet.component';
import { InventoryBillingComponent } from './pages/invoice/inventory/inventory-billing/inventory-billing.component';
import { InventoryBillingSpecialComponent } from './pages/invoice/inventory/inventory-special-billing/inventory-special-billing.component';
import { InventoryBillingReceiptComponent } from './pages/invoice/inventory/inventory-billing-receipt/inventory-billing-receipt.component';
import { PreventaComponent } from "./pages/invoice/inventory/preventa/preventa.component"; //PREVENTA
import { RestaurantComponent } from "./pages/invoice/inventory/restaurant/restaurant.component";//RESTAURANT
import { DescriptionBillingComponent } from './pages/invoice/description/description-billing/description-billing.component';
import { DairyComponent } from './pages/accounting/dairy/dairy.component';
import { WarehouseComponent } from './pages/inventory/warehouse/warehouse.component';
import { TransferComponent } from './pages/inventory/transfer/transfer.component';
import { InventoryFuelComponent } from './pages/invoice/inventory/inventory-fuel/inventory-fuel.component';
import { TransferProcessComponent } from './pages/inventory/transfer-process/transfer-process.component';
import { MultiCompanyUserComponent } from './pages/user/multi-company-user/multi-company-user.component';
import { ReportStoreComponent } from './pages/invoice/invoice-report/report-store/report-store.component';
import { ReportCompanyComponent } from './pages/invoice/invoice-report/report-company/report-company.component';
import { CancellationInvoiceComponent } from './pages/invoice/cancellation/cancellation-invoice/cancellation-invoice.component';
import { CancellationExchangeBillComponent } from './pages/invoice/cancellation/cancellation-exchange-bill/cancellation-exchange-bill.component';
import { ReprintComponent } from './pages/invoice/reprint/reprint.component';
import { PurchaseItemReportComponent } from './pages/purchase/purchase-item-report/purchase-item-report.component';
import { TransferReportComponent } from './pages/inventory/transfer-report/transfer-report.component';
import { TransferReportsComponent } from './pages/inventory/transfer-reports/transfer-reports.component';
import { PhraseStageComponent } from './pages/catalogue/phrase-stage/phrase-stage.component';
import { ReprintInvoiceComponent } from './pages/invoice/reprint/reprint-invoice/reprint-invoice.component';
import { ReprintExchangeBillComponent } from './pages/invoice/reprint/reprint-exchange-bill/reprint-exchange-bill.component';
import { RetentionsReportComponent } from './pages/accounting/retentions-report/retentions-report.component';
import { PurchaseDetailComponent } from './pages/purchase/purchase-detail/purchase-detail.component';
import { RetentionsInvoiceReportComponent } from './pages/accounting/retentions-invoice-report/retentions-invoice-report.component';
import { InvoiceRecurrentComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent.component';
import { InvoiceRecurrentMaintenanceComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent-maintenance/invoice-recurrent-maintenance.component';
import { InvoiceRecurrentInsertComponent } from './pages/invoice/invoice-recurrent/invoice-recurrent-insert/invoice-recurrent-insert.component';
import { XmlLoadComponent } from './pages/xml-load/xml-load.component';
import { ReprintNotesComponent } from './pages/invoice/reprint/notes/reprint-notes.component';
import { ReportSylvaniaComponent } from './pages/xml-load/report-sylvania/report-sylvania.component';
import { CancellationReceiptComponent } from './pages/invoice/cancellation/cancellation-receipt/cancellation-receipt.component';
import { ReportReceiptCompanyComponent } from './pages/invoice/invoice-report/report-receipt-company/report-receipt-company.component';
import { InventoryLogComponent} from './pages/inventory/inventory-log/inventory-log.component';
import { InvoiceMassive } from './pages/invoice/invoice-massive/invoice-massive-inventory/invoice-massive.component';
import { InventoryAllComponent } from './pages/inventory/inventory-all/inventory-all.component';
import { InventoryBillingCardsComponent } from './pages/invoice/inventory/inventory-billing-cards/inventory-billing-cards.component';
import { PurchaseProcessBiokalComponent } from './pages/purchase/purchase-process-biokal/purchase-process-biokal.component';
import { BankAccountComponent } from './pages/accounting/bank-account/bank-account.component';
import { AccountStatementComponent } from './pages/accounting/account-statement/account-statement.component';
import { ReportCompanySummaryComponent } from './pages/invoice/invoice-report/report-company-summary/report-company-summary.component';
import { InventoryBillingFuelCardsComponent } from './pages/invoice/inventory/inventory-billing-fuel-cards/inventory-billing-fuel-cards.component';

const routes: Routes = [
  //Validación he Inicio
  { path: 'userAutoConfirm/:userEmail/:verificationCode', component: UserAutoConfirmComponent },
  { path: 'profile', component: ProfileComponent },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  
  // Usuario y Perfiles
  { path: 'userLogin', component: LoginComponent },
  { path: 'user', component: UserComponent},
  { path: 'multi-company-user', component: MultiCompanyUserComponent},
  { path: 'permission', component: PermissionComponent},
  { path: 'access', component: AccessComponent},

  // Empresa
  { path: 'company', component: CompanyComponent},
  { path: 'owner', component: OwnerComponent},
  { path: 'store', component: StoreComponent},
  { path: 'manager', component: ManagerComponent},
  
  // Compras
  { path: 'purchase-insert', component: PurchaseInsertComponent },
  { path: 'purchase-pending', component: PurchasePendingComponent },
  { path: 'purchase-process', component: PurchaseProcessComponent },
  { path: 'purchase-process-biokal', component: PurchaseProcessBiokalComponent },
  { path: 'purchase-view', component: PurchaseViewComponent },
  { path: 'purchase-detail', component: PurchaseDetailComponent },
  { path: 'purchase-item-report', component: PurchaseItemReportComponent },
  
  // Contabilidad
  { path: 'payment-invoice', component: PaymentInvoiceComponent },
  { path: 'payment-purchase', component: PaymentPurchaseComponent },
  { path: 'payment-records', component: PaymentRecordsComponent },
  { path: 'balance-sheet', component: BalanceSheetComponent },
  { path: 'retentions-report', component: RetentionsReportComponent },
  { path: 'retentions-invoice-report', component: RetentionsInvoiceReportComponent },
  { path: 'dairy', component: DairyComponent },
  { path: 'bank-account', component: BankAccountComponent },
  { path: 'account-statement', component: AccountStatementComponent },
  
  // Admin
  { path: 'client', component: ClientComponent },
  { path: 'provider', component: ProviderComponent},
  { path: 'inventory', component: InventoryComponent},
  { path: 'warehouse', component: WarehouseComponent},
  { path: 'transfer', component: TransferComponent},
  { path: 'transfer-process', component: TransferProcessComponent},
  { path: 'transfer-report', component: TransferReportComponent},
  { path: 'transfer-reports', component: TransferReportsComponent},
  { path: 'inventory-log', component: InventoryLogComponent},
  { path: 'inventory-all', component: InventoryAllComponent},
  
  // Facturacion
  { path: 'description-billing', component: DescriptionBillingComponent }, 
  { path: 'inventory-billing', component: InventoryBillingComponent },
  { path: 'description-credit-note', component: DescriptionCreditNoteComponent },
  { path: 'inventory-credit-note', component: InventoryCreditNoteComponent },
  { path: 'description-debit-note', component: DescriptionDebitNoteComponent },
  { path: 'inventory-debit-note', component: InventoryDebitNoteComponent },
  { path: 'description-exchange-note', component: DescriptionExchangeBillComponent },
  { path: 'inventory-exchange-note', component: InventoryExchangeBillComponent },
  { path: 'description-export-invoice', component: DescriptionExportInvoiceComponent },
  { path: 'inventory-export-invoice', component: InventoryExportInvoiceComponent },
  { path: 'inventory-billing-fuel', component: InventoryFuelComponent },
  { path: 'preventa', component: PreventaComponent },
  { path: 'restaurant', component: RestaurantComponent },
  { path: 'inventory-special-billing', component: InventoryBillingSpecialComponent },
  { path: 'inventory-billing-receipt', component: InventoryBillingReceiptComponent },
  { path: 'cancellation-receipt', component: CancellationReceiptComponent },
  { path: 'report-receipt-company', component: ReportReceiptCompanyComponent },
  { path: 'invoice-massive', component: InvoiceMassive },
  { path: 'inventory-billing-cards', component: InventoryBillingCardsComponent },
  { path: 'inventory-billing-fuel-cards', component: InventoryBillingFuelCardsComponent },

  //Recurrente
  { path: 'invoice-recurrent', component: InvoiceRecurrentComponent },
  { path: 'invoice-recurrent-maintenance', component: InvoiceRecurrentMaintenanceComponent },
  { path: 'invoice-recurrent-insert', component: InvoiceRecurrentInsertComponent },

  //Re-imprimir
  { path: 'reprint', component: ReprintComponent },
  { path: 'reprint-invoice', component: ReprintInvoiceComponent },
  { path: 'reprint-debit-note', component: ReprintNotesComponent },
  { path: 'reprint-credit-note', component: ReprintNotesComponent },
  { path: 'reprint-exchange-bill', component: ReprintExchangeBillComponent },
  
  //Cancelar
  { path: 'cancellation', component: CancellationComponent },
  { path: 'cancellation-invoice', component: CancellationInvoiceComponent },
  { path: 'cancellation-exchange-bill', component: CancellationExchangeBillComponent },
  { path: 'report-store', component: ReportStoreComponent },
  { path: 'report-company', component: ReportCompanyComponent },
  { path: 'report-company-summary', component: ReportCompanySummaryComponent },

  // Manto
  { path: 'client-category', component: ClientCategoryComponent },
  { path: 'provider-category', component: ProviderCategoryComponent },
  { path: 'product', component: ProductComponent},
  { path: 'company-type', component: CompanyTypeComponent},
  { path: 'company-plan', component: CompanyPlanComponent},
  { path: 'phrase-stage', component: PhraseStageComponent},

  // Sylvania  
  { path: 'xml-wm', component: XmlLoadComponent },
  { path: 'xml-fcam', component: XmlLoadComponent },
  { path: 'xml-ncred', component: XmlLoadComponent },
  { path: 'report-sale', component: ReportSylvaniaComponent },
  { path: 'report-book', component: ReportSylvaniaComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
