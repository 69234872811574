import { Injectable } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LetrasPipe } from 'src/app/pipe/letras/letras.pipe';

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    pipeString = new LetrasPipe();
    pipeDate = new DatePipe('en-US');

    constructor(
        private pipeNumber: DecimalPipe,
    ) { }

    //NOTAS DE TRANSFERENCIA 
    async noteTransfer(transfer, detail, company) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
              @media all {
                  div.saltopagina {
                      display: none;
                  }
              }
          
              @media print {
                  div.saltopagina {
                      display: block;
                      page-break-before: always;
                  }
          
                  @page {
                      margin: 0;
                      size: auto;
                      transform: scale(1);                      
                      color-adjust: exact;
                      -webkit-print-color-adjust: exact;
                  }   
                  
                  html { background: white !important }      
                  
                  body { height: auto; !important }
              }

              html {
                     background: #e3e3e361;
                     font-family: sans-serif;
                    }

              body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
              }
          
              table {
                  width: 100%;
                  margin: 0px auto;
                  font-size: 0.75rem;
              }
          
              .letter {                  
                  margin: 0px auto;
                  padding-top: 1rem;
                  line-height: 0.85rem;     
              }
          
              .container {
                  width: 94%;
                  padding: 0.5rem;
                  margin: 0px auto;
                  position: relative;
                  border-radius: 1rem;
                  border: 1px solid #000000;
              }
          
              .font-s {
                  font-size: 0.6rem;
                  font-weight: bold;
              }
          
              .font-b {
                  font-size: 1rem;
                  font-weight: bold;
              }
          
              .bg-b {
                  color: #ffffff;
                  font-weight: bold;
                  background: #4195f3;
              }
          
              .total {
                  color: #ffffff;
                  font-weight: bold;        
                  background: #4195f3;
                  border: 1px solid #000000;        
              }
          
              .totalNo {
                  font-weight: bold;
                  border: 1px solid #000000;
              }
          
              input[type=checkbox] {
                  -webkit-transform: scale(1.2);
              }
          </style>`;

        var html = `
          <!DOCTYPE html>
          <html>
          
          <head>
              <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
              <title>${transfer.transferType == "1" ? 'NOTA DE SALIDA: ' : 'NOTA DE INGRESO'} ${transfer.transferBusinessCode}</title>
          </head>
          
          <body>
              <div class="letter">
                  <div class="container">            
                      <table>
                          <tr align="center">
                              <td width="20%">
                                  <img src="${company.companyLogoUrl}"
                                      style="max-height: 6rem" />
                              </td>
                              <td width="55%">
                                  <b class="font-b">${company.companyName}</b>
                                  <br>${company.companyAddress}
                                  <br>PBX: ${company.companyPhone}
                                  <br class="font-s">web: ${company.companyUrl}
                                  <br>NIT: ${company.companyTaxDocument}
                              </td>
                              <td width="25%" class="font-b">
                                  <div>${transfer.transferType == "1" ? 'NOTA DE SALIDA: ' : 'NOTA DE INGRESO'}</div>
                                  <div>${transfer.transferBusinessCode}
                                  </div>
                              </td>
                          </tr>
                      </table>
                      <table>
                          <tr align="center" class="bg-b">
                              <td colspan="9">DATOS DE CLIENTE</td>
                          </tr>
                          <tr>
                              <td width="10%">NOMBRE:</td>
                              <td width="50%">${transfer.bodega}</td>
                              <td width="10%" align="right">NIT:</td>
                              <td width="30%">${transfer.clientTaxDocument == null ? 'N/A' : transfer.clientTaxDocument}</td>
                          </tr>
                          <tr>
                              <td>DIRECCIÓN:</td>
                              <td>${transfer.direccion}</td>
                              <td align="right">FECHA:</td>
                              <td>${transfer.fecha}</td>
                          </tr>
                          <tr>
                              <td>TÉLEFONO:</td>
                              <td>${transfer.warehousePhone}</td>
                              <td align="right">ASESOR:</td>
                              <td>${transfer.usuario}</td>
                          </tr>
                      </table>
                      <table>
                          <tr align="center" class="bg-b">
                              <td width="7%">CANT.</td>
                              <td width="17%">CÓDIGO</td>
                              <td width="50%">DESCRIPCIÓN</td>
                              <td width="13%">PRECIO U.</td>
                              <td width="13%">TOTAL</td>
                          </tr>`;
        let total = 0;

        for (const [i, e] of detail.entries()) {
            total += parseFloat(e.subtotal);
            const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';
            html += `<tr style="min-height: 6rem;">
                              <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.quantity), '1.2-2')}</td>
                              <td style="background: ${color}">${e.code}</td>
                              <td style="background: ${color}">${e.description}</td>
                              <td style="background: ${color}" align="right">${this.pipeNumber.transform(e.unitPrice, '1.2-2')}</td>
                              <td style="background: ${color}" align="right">${this.pipeNumber.transform(e.subtotal, '1.2-2')}</td>
                          </tr>`;
        };

        html += `<tr align="center">
                              <td colspan="3"></td>
                              <td class="total">TOTAL Q</td>
                              <td class="totalNo"><b>${this.pipeNumber.transform(total, '1.2-2')}</b></td>
                          </tr> 
                      </table>
          
                      <br>
                      <table style="height: 3.5rem;">
                          <tr>
                              <td>PRESTAMO</td>
                              <td><input type="checkbox" disabled ${transfer.transferRazon == "1" ? 'checked' : ''} /></td>
                              <td>VENTA</td>
                              <td><input type="checkbox" disabled ${transfer.transferRazon == "2" ? 'checked' : ''} /></td>
                              <td>_______________________________________________________________</td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>Garantía</td>
                              <td><input type="checkbox" disabled ${transfer.transferRazon == "3" ? 'checked' : ''} /></td>
                              <td>OTRO</td>
                              <td><input type="checkbox" disabled ${transfer.transferRazon == "4" ? 'checked=' : ''}/></td>
                              <td>${transfer.transferRazon == "4" ? transfer.transOtherRazonDesc : '_______________________________________________________________'}</td>
                              <td width="30%" align="center" class="font-s">FIRMA QUIEN RECIBE</td>
                          </tr>
                      </table>
                  </div>
              </div>
              <div class="saltopagina"></div>
          </body>
          </html>`;

        await pdfWindow.document.write(css + html);
    }

    //NOTAS DE TRANSFERENCIA 
    async noteTransferNail(transfer, detail, company) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
              @media all {
                  div.saltopagina {
                      display: none;
                  }
              }
          
              @media print {
                  div.saltopagina {
                      display: block;
                      page-break-before: always;
                  }
          
                  @page {
                      margin: 0;
                      size: auto;
                      transform: scale(1);                      
                      color-adjust: exact;
                      -webkit-print-color-adjust: exact;
                  }   
                  
                  html { background: white !important }      
                  
                  body { height: auto; !important }
              }

              html {
                     background: #e3e3e361;
                     font-family: sans-serif;
                    }

              body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
              }
          
              table {
                  width: 100%;
                  margin: 0px auto;
                  font-size: 0.75rem;
              }
          
              .letter {                  
                  margin: 0px auto;
                  padding-top: 1rem;
                  line-height: 0.85rem;     
              }
          
              .container {
                  width: 94%;
                  padding: 0.5rem;
                  margin: 0px auto;
                  position: relative;
                  border-radius: 1rem;
                  border: 1px solid #000000;
              }
          
              .font-s {
                  font-size: 0.6rem;
                  font-weight: bold;
              }
          
              .font-b {
                  font-size: 1rem;
                  font-weight: bold;
              }
          
              .bg-b {
                  color: #ffffff;
                  font-weight: bold;
                  background: #4195f3;
              }
          
              .total {
                  color: #ffffff;
                  font-weight: bold;        
                  background: #4195f3;
                  border: 1px solid #000000;        
              }
          
              .totalNo {
                  font-weight: bold;
                  border: 1px solid #000000;
              }
          
              input[type=checkbox] {
                  -webkit-transform: scale(1.2);
              }
          </style>`;

        var html = `
          <!DOCTYPE html>
          <html>
          
          <head>
              <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
              <title>${transfer.transferType == "1" ? 'NOTA DE SALIDA: ' : 'NOTA DE INGRESO'} ${transfer.transferBusinessCode}</title>
          </head>
          
          <body>
              <div class="letter">
                  <div class="container">            
                      <table>
                          <tr align="center">
                              <td width="20%">
                                  <img src="${company.companyLogoUrl}"
                                      style="max-height: 6rem" />
                              </td>
                              <td width="55%">
                                  <b class="font-b">${company.companyName}</b>
                                  <br>${company.companyAddress}
                                  <br>PBX: ${company.companyPhone}
                                  <br class="font-s">web: ${company.companyUrl}
                                  <br>NIT: ${company.companyTaxDocument}
                              </td>
                              <td width="25%" class="font-b">
                                  <div>${transfer.transferType == "1" ? 'NOTA DE SALIDA: ' : 'NOTA DE INGRESO'}</div>
                                  <div>${transfer.transferBusinessCode}
                                  </div>
                              </td>
                          </tr>
                      </table>
                      <table>
                          <tr align="center" class="bg-b">
                              <td colspan="9">DATOS DE CLIENTE</td>
                          </tr>
                          <tr>
                              <td width="10%">NOMBRE:</td>
                              <td width="50%">${transfer.bodega}</td>
                              <td width="10%" align="right">NIT:</td>
                              <td width="30%">${transfer.clientTaxDocument == null ? 'N/A' : transfer.clientTaxDocument}</td>
                          </tr>
                          <tr>
                              <td>DIRECCIÓN:</td>
                              <td>${transfer.direccion}</td>
                              <td align="right">FECHA:</td>
                              <td>${transfer.fecha}</td>
                          </tr>
                          <tr>
                              <td>TÉLEFONO:</td>
                              <td>${transfer.warehousePhone}</td>
                              <td align="right">ASESOR:</td>
                              <td>${transfer.usuario}</td>
                          </tr>
                      </table>
                      <table>
                          <tr align="center" class="bg-b">                              
                              <td width="17%">CÓDIGO</td>
                              <td width="50%">DESCRIPCIÓN</td>
                              <td width="7%">CANT.</td>
                          </tr>`;
        let total = 0;

        for (const [i, e] of detail.entries()) {
            total += parseFloat(e.subtotal);
            const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';
            html += `<tr style="min-height: 6rem;">                              
                              <td style="background: ${color}">${e.code}</td>
                              <td style="background: ${color}">${e.description}</td>
                              <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.quantity), '1.0-0')}</td>
                          </tr>`;
        };

            html += `</table>
          
                      <br>
                        <table style="height: 3.5rem;">
                            <tr>
                                <td>PRESTAMO</td>
                                <td><input type="checkbox" disabled ${transfer.transferRazon == "1" ? 'checked' : ''} /></td>
                                <td>VENTA</td>
                                <td><input type="checkbox" disabled ${transfer.transferRazon == "2" ? 'checked' : ''} /></td>
                                <td>_______________________________________________________________</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Garantía</td>
                                <td><input type="checkbox" disabled ${transfer.transferRazon == "3" ? 'checked' : ''} /></td>
                                <td>OTRO</td>
                                <td><input type="checkbox" disabled ${transfer.transferRazon == "4" ? 'checked=' : ''}/></td>
                                <td>${transfer.transferRazon == "4" ? transfer.transOtherRazonDesc : '_______________________________________________________________'}</td>
                                <td width="30%" align="center" class="font-s">FIRMA QUIEN RECIBE</td>
                            </tr>
                        </table>
                      </br>
                      <table style="height: 3.5rem;">
                        <tr>
                            <td>OBSERVACIONES</td>
                        </tr>
                        <tr>
                            <td><textarea name="textarea" rows="10" cols="75"></textarea></td>
                        </tr>
                      </table>
                  </div>
              </div>
              <div class="saltopagina"></div>
          </body>
          </html>`;

        await pdfWindow.document.write(css + html);
    }

    async cancelTransfer(date, userName, company, transfer, detail) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
              @media all {
                  div.saltopagina {
                      display: none;
                  }
              }
          
              @media print {
                  div.saltopagina {
                      display: block;
                      page-break-before: always;
                  }
          
                  @page {
                      margin: 0;
                      size: auto;
                      color-adjust: exact;
                      -webkit-print-color-adjust: exact;
                  }   
                  
                  html { background: white }                  
              }

              html { background: gray }

              body {
                width: 8.5in;
                height: 11in;
                margin: 0px auto;
                background: white; 
              }
          
              table {
                  width: 100%;
                  margin: 0px auto;
                  font-size: 0.75rem;
              }
          
              .letter {                  
                  margin: 0px auto;
                  padding-top: 1rem;
                  line-height: 0.85rem;     
              }
          
              .container {
                  width: 94%;
                  padding: 0.5rem;
                  margin: 0px auto;
                  position: relative;
                  border-radius: 1rem;
                  border: 1px solid #000000;
              }
          
              .font-s {
                  font-size: 0.6rem;
                  font-weight: bold;
              }
          
              .font-b {
                  font-size: 1rem;
                  font-weight: bold;
              }
          
              .bg-b {
                  color: #ffffff;
                  font-weight: bold;
                  background: #4195f3;
              }
          
              .total {
                  color: #ffffff;
                  font-weight: bold;        
                  background: #4195f3;
                  border: 1px solid #000000;        
              }
          
              .totalNo {
                  font-weight: bold;
                  border: 1px solid #000000;
              }
          
              input[type=checkbox] {
                  -webkit-transform: scale(1.2);
              }
          </style>`;

        var html = `
          <!DOCTYPE html>
          <html>
          
          <head>
              <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
              <title>ANULACION TRANS.: ${transfer.transferBusinessCode}</title>
          </head>
          
          <body>
              <div class="letter">
                  <div class="container">
                      <table>
                          <tr align="center">
                              <td width="20%">
                                  <img src="${company.companyLogoUrl}"
                                      style="max-height: 6rem" />
                              </td>
                              <td width="55%">
                                  <b class="font-b">${company.companyName}</b>
                                  <br>${company.companyAddress}
                                  <br>PBX: ${company.companyPhone}
                                  <br class="font-s">web: ${company.companyUrl}
                                  <br>NIT: ${company.companyTaxDocument}
                              </td>
                              <td width="25%" class="font-b">
                                  ANULACIÓN TRANSFERENCIA
                                  <br> NO. ${transfer.transferBusinessCode}
                              </td>
                          </tr>
                      </table>
          
                      <table>
                          <tr align="center" class="bg-b">
                              <td colspan="9">DATOS DE LA ANULACIÓN</td>
                          </tr>
                          <tr>
                              <td width="10%">A NOMBRE:</td>
                              <td width="65%">${transfer.bodega}</td>
                              <td width="10%" align="right">NIT:</td>
                              <td width="15%">${transfer.clientTaxDocument == null ? 'N/A' : transfer.clientTaxDocument}</td>
                          </tr>
                          <tr>
                              <td>ANULO:</td>
                              <td>${userName}</td>                              
                              <td align="right">FECHA:</td>
                              <td>${date}</td>
                          </tr>
                      </table>
          
                      <table>
                          <tr align="center" class="bg-b">
                              <td width="5%">CANT.</td>
                              <td width="15%">CÓDIGO</td>
                              <td width="40%">DESCRIPCIÓN</td>
                              <td width="10%">PRECIO U.</td>
                              <td width="10%">TOTAL</td>
                          </tr>                                                 
                          `;
        let total = 0;

        for (const [i, e] of detail.entries()) {
            total += parseFloat(e.subtotal);
            const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';

            html += `<tr style="min-height: 6rem;">
                              <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.quantity), '1.2-2')}</td>
                              <td style="background: ${color}">${e.code}</td>
                              <td style="background: ${color}">${e.description}</td>
                              <td style="background: ${color}" align="right">${this.pipeNumber.transform(e.unitPrice, '1.2-2')}</td>
                              <td style="background: ${color}" align="right">${this.pipeNumber.transform(e.subtotal, '1.2-2')}</td>
                          </tr>`;
        };

        html += `<tr align="center">
                              <td colspan="3"></td>
                              <td class="total">TOTAL Q</td>
                              <td class="totalNo"><b>${this.pipeNumber.transform(total, '1.2-2')}</b></td>
                          </tr> 
                      </table>          
                      <br>                      
                  </div>
              </div>
              <div class="saltopagina"></div>
          </body>
          </html>`;

        await pdfWindow.document.write(css + html);
    }

    //FACTURA CERTIFICADORES
    printInfile(invoiceAuthorization) {
        window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + invoiceAuthorization);
    }

    printMegaprint(invoicePdf) {
        const pdfWindow = window.open("")
        pdfWindow.document.write('<title>Clever Sistema</title>');
        pdfWindow.document.write('<link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">');
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(invoicePdf) + "'></iframe>")
    }

    printLetter(company, store, userName, dte, fel, felDetail) {
        console.log(company);
        console.log(store);
        console.log(userName);
        console.log(dte);
        console.log(fel);
        console.log(felDetail);

        let regimenMessage = '';
        company.companyTaxToPay == 12 ? regimenMessage = "Sujeto a pagos trimestrales ISR" : regimenMessage = "*No genera derecho a crédito fiscal";
        
        const pdfWindow = window.open();
        const css = `<style>   
            td { font-size: 12px; padding: 2px;}     
            .brDescr {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF; border-bottom: 1px solid  #2CA8FF;}
            .brCompl {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF;}
          </style>`;
        var html = `
        <head><title>Factura "${company.companyName + ' - ' + store.storeName}"</title></head>
        <body style="width: 21cm; height: 27cm; margin: auto; font-family: sans-serif, system-ui, monospace;"> 
          <div style="border: 2px solid #2CA8FF; border-radius: 10px; width: auto;">
            <table style="width: 100%;">
                  <tr>
                      <td rowspan="6" width="19%"><img style="width: 110px; margin:10px auto; display:block;" src="${company.companyLogoUrl}"></td>
                      <th width="62%" style="font-size: 19px; text-align: center;">${fel.transmitter.transmitterComercialName}</th>
                      <td width="19%" style="font-size: 10px;">Vendedor: ${userName}</td>
                  </tr>
                  <tr>
                      <td style="text-align: center;">${fel.transmitter.transmitterName}</td>
                      <td style="font-size: 10px;">Letra: ${'.'}. No.: ${'.'}</td>                      
                  </tr>
                  <tr>
                      <td style="text-align: center;">NIT: ${fel.transmitter.transmitterTaxDocument}</td>
                      <td style="font-size: 10px;">Factura Electronica Fel</td>                      
                  </tr>
                  <tr>
                      <td style="text-align: center;">Tel: ${store.storePhone}</td>
                      <td>Serie: ${dte.serie}</td>                      
                  </tr>
                  <tr>
                      <td style="text-align: center;">                          
                          ${fel.transmitter.transmitterAddress}. ${fel.transmitter.transmitterMunicipality}, ${fel.transmitter.transmitterDepartment}
                      </td>
                      <td>No. DTE: ${dte.noFactura}</td>
                  </tr>
                  <tr>
                      <th style="text-align: center;">FACTURA</th>
                      <td>Fecha: ${this.pipeDate.transform(fel.invoice.invoiceDate, 'dd-MM-yyy')}</td>
                  </tr>
            </table>
          </div>
          <br>
          <div style="border: 2px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: auto; min-height: 750px" >
            <table cellspacing="0" cellpadding="0" style="width: 100%;">
                <tr style="max-height: 15px;">
                  <td colspan="4"> Nombre: ${fel.receiver.receiverName}</td>
                  <td colspan="3"> Nit: ${fel.receiver.receiverTaxDocument}</td>
                </tr>
                <tr style="max-height: 15px;">
                  <td colspan="7"> Dirección: ${fel.receiver.receiverAddress}</td>
                </tr>
                <tr style="font-size: 11px; text-align: center;">
                  <th class="brDescr">CANTIDAD</th>
                  <th class="brDescr">TIPO</th>
                  <th class="brDescr" style="width: 320px;">DESCRIPCIÓN</th>
                  <th class="brDescr">PRECIO UNI.</th>
                  <th class="brDescr">IMPUESTO</th>
                  <th class="brDescr">DESCUENTO</th>
                  <th class="brDescr" style="width: 120px;">TOTAL</th>
                </tr>`;

        for (const e of felDetail) {
            html += `<tr">
            <td style="text-align: center; "> ${this.pipeNumber.transform(e.quantity, '1.2-2')}</td>
            <td style="text-align: center;"> ${e.isService}</td>
            <td>${e.description}</td>
            <td style="text-align: right;"> ${this.pipeNumber.transform(e.unitPrice, '1.2-2')}</td>
            <td style="text-align: right;"> ${this.pipeNumber.transform(e.taxAmount, '1.2-2')}</td>
            <td style="text-align: right;"> ${this.pipeNumber.transform(e.discount, '1.2-2')}</td>
            <td style="text-align: right;"> ${this.pipeNumber.transform(e.total, '1.2-2')}</td>
            </tr>`
        };

        html += `          
            </table>
          </div>
          <div style="border: 2px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: auto;">
            <table cellspacing="0" style="width: 100%;">
                  <tr>
                    <td colspan="7" style="text-align: center; font-size: 10px;">${regimenMessage}</td>
                  </tr>
                  <tr style="height: 30px;">
                    <td class="brCompl" style="max-width: 22px; padding-left: 5px;">Total en Letras:</td>
                      <td class="brCompl" style="font-size: 10px max-width: 65px; text-align: left;">${this.pipeString.transform(fel.invoice.invoiceTotal)}</td>
                      <th style="font-size: 14px; max-width: 65px; text-align: right; border-top: 1px solid  #2CA8FF;">
                        TOTAL: Q ${this.pipeNumber.transform(fel.invoice.invoiceTotal, '1.2-2')}
                      </th>
                  </tr>
              </table>
          </div> 
          <br>
          <table style="border: 2px solid #2CA8FF; border-radius: 10px; width: 100%;" padding: 10px;" >
              <tr><td>
                No. de Autorización: ${dte.autorizacion}<br>
                Fecha de Certificación: ${dte.fechaCertificacion}  
              </td></tr>
              <tr><td style="text-align: center">Certificador: ${dte.certificador}. Nit: ${dte.nitCertificador}</td></tr>
          </table>
        </body>`;

        pdfWindow.document.write(css + html);
    }

    async prevent_print(prevent, detail, company, correlativo, paymets, client) {
        const css = `<style type="text/css">
                    @media all {
                        div.saltopagina {
                            display: none;
                        }
                    }
                
                    @media print {
                        div.saltopagina {
                            display: block;
                            page-break-before: always;
                        }
                
                        @page {
                            margin: 0;
                            size: auto;
                            transform: scale(1);                      
                            color-adjust: exact;
                            -webkit-print-color-adjust: exact;
                        }   
                        
                        html { background: white !important }      
                        
                        body { height: auto; !important }
                    }

                    html {
                            background: #e3e3e361;
                            font-family: sans-serif;
                        }

                    body {
                    width: 8.5in;
                    height: auto; /*11in;*/
                    min-height:  5in;
                    margin: 0px auto;
                    background: white; 
                    }
                
                    table {
                        width: 100%;
                        margin: 0px auto;
                        font-size: 0.75rem;
                    }
                
                    .letter {                  
                        margin: 0px auto;
                        padding-top: 1rem;
                        line-height: 0.85rem;     
                    }
                
                    .container {
                        width: 94%;
                        padding: 0.5rem;
                        margin: 0px auto;
                        position: relative;
                        border-radius: 1rem;
                        border: 1px solid #000000;
                    }
                
                    .font-s {
                        font-size: 0.6rem;
                        font-weight: bold;
                    }
                
                    .font-b {
                        font-size: 1rem;
                        font-weight: bold;
                    }
                
                    .bg-b {
                        color: #000000;
                        font-weight: bold;
                        background: #add1f7;
                    }
                
                    .total {
                        color: #000000;
                        font-weight: bold;        
                        background: #add1f7;
                        border: 1px solid #000000;        
                    }
                
                    .totalNo {
                        font-weight: bold;
                        border: 1px solid #000000;
                    }
                
                    input[type=checkbox] {
                        -webkit-transform: scale(1.2);
                    }
                </style>`;

        var html = `
                <!DOCTYPE html>
                <html style="background: #e3e3e361; font-family: sans-serif;">
                
                <head>
                    <title>Preventa No. ${correlativo}</title>
                </head>
                
                <body>
                    <div class="letter" style="margin: 0px auto; padding-top: 1rem; line-height: 0.85rem;">
                        <div class="container" style="width: 94%; padding: 0.5rem; margin: 0px auto; position: relative; border-radius: 1rem; border: 1px solid #000000;">            
                            <table style="font-size: 0.75rem;">
                                <tr align="center">
                                    <td width="20%">
                                        <img src="${company.companyLogoUrl}"
                                            style="max-height: 6rem" />
                                    </td>
                                    <td width="55%">
                                        <b style="font-size: 1rem; font-weight: bold;">${
                                        company.companyName
                                        }</b>
                                        <br>${company.companyAddress}
                                        <br>PBX: ${company.companyPhone}
                                        <br style="font-size: 0.6rem; font-weight: bold;">web: ${
                                        company.companyUrl
                                        }
                                        <br>NIT: ${company.companyTaxDocument}
                                    </td>
                                    <td width="25%" style="font-size: 1rem; font-weight: bold;">
                                        <div>PREVENTA</div>
                                        <div>${correlativo}</div>
                                    </td>
                                </tr>
                            </table>
                            <table style="font-size: 0.75rem">
                                <tr align="center" style="color: #000000; font-weight: bold; background: #add1f7;">
                                    <td colspan="9">DATOS DE CLIENTE</td>
                                </tr>
                                <tr>
                                    <td width="10%">NOMBRE:</td>
                                    <td width="50%">${
                                    client.clientNameFrequent
                                    }</td>
                                    <td width="10%" align="right">NIT:</td>
                                    <td width="30%">${
                                    prevent.receiver.receiverTaxDocument == null
                                        ? "CF"
                                        : prevent.receiver.receiverTaxDocument
                                    }</td>
                                </tr>
                                <tr>
                                    <td>DIRECCIÓN:</td>
                                    <td>${client.clientAddressFrequent}</td>
                                    <td align="right">FECHA:</td>
                                    <td>${prevent.invoice.invoiceDate}</td>
                                </tr>
                                <tr>
                                    <td align="right">Telefono:</td>
                                    <td>${client.clientPhone2}</td>                                    
                                    <td align="right">ASESOR:</td>
                                    <td>${prevent.user.userCode}</td>
                                </tr>                                
                            </table>
                            <table>
                                <tr align="center" class="bg-b">
                                    <td width="7%">CANT.</td>
                                    <td width="17%">CÓDIGO</td>
                                    <td width="50%">DESCRIPCIÓN</td>
                                    <td width="13%">PRECIO U.</td>
                                    <td width="13%">TOTAL</td>
                                </tr>`;
        let total = 0;

        for (const [i, e] of detail.entries()) {
        total += parseFloat(e.subtotal);
        const color = i % 2 != 0 ? "#dfdfdf" : "#ffffff";
        html += `<tr style="min-height: 6rem;">
                                <td style="background: ${color}" align="center">${this.pipeNumber.transform(
            parseFloat(e.quantity),
            "1.2-2"
        )}</td>
                                <td style="background: ${color}">${
            e.itemCode
        }</td>
                                <td style="background: ${color}">${
            e.description
        }</td>
                                <td style="background: ${color}" align="right">${this.pipeNumber.transform(
            e.unitPrice,
            "1.2-2"
        )}</td>
                                <td style="background: ${color}" align="right">${this.pipeNumber.transform(
            e.total,
            "1.2-2"
        )}</td>
                            </tr>`;
        }

            html += `<tr align="center">
                            <td colspan="3"></td>
                            <td style="color: #000000; font-weight: bold; background: #add1f7; border: 1px solid #000000; ">TOTAL Q</td>
                            <td class="totalNo" style="font-weight: bold; border: 1px solid #000000;"><b>${this.pipeNumber.transform(
                            prevent.invoice.invoiceTotal,
                            "1.2-2"
                            )}</b></td>
                        </tr> 
                    </table>
                    <br>
                    <br>
                    <p>--------------------------------------------------------------------------------------------------------------------------------------------</p>
                    <table>
                                <tr align="center" class="bg-b">
                                    <td width="50%">Forma de Pago</td>
                                    <td width="13%">Monto</td>
                                </tr>`;

                        for (const [i, p] of paymets.entries()) {
                        const color = i % 2 != 0 ? "#dfdfdf" : "#ffffff";
                        html += `   <tr style="min-height: 6rem;">
                                        <td style="background: ${color}">${p.NamePaymentType}</td>
                                        <td style="background: ${color}">${p.MontoPayment}</td>
                                    </tr>`;
                        }

            html += `
                    </table>
                    <br>
                    <br>
                    <p>--------------------------------------------------------------------------------------------------------------------------------------------</p>
                    <br>
                    <table style="font-size: 0.75rem">
                        <tr align="center" style="color: #000000; font-weight: bold; background: #add1f7;">
                            <td colspan="9">DATOS DE CLIENTE</td>
                        </tr>
                        <tr>
                            <td width="10%">NOMBRE:</td>
                            <td width="50%">${
                            client.clientNameFrequent
                            }</td>
                            <td width="10%" align="right">FECHA:</td>
                            <td width="30%">${prevent.invoice.invoiceDate}</td>
                        </tr>
                        <tr>
                            <td>DIRECCIÓN:</td>
                            <td>${client.clientAddressFrequent}</td>
                            <td>Telefono:</td>
                            <td>${client.clientPhone2}</td>
                        </tr>
                        <tr>
                            <td>ASESOR:</td>
                            <td>${prevent.user.userCode}</td>
                            <td></td>
                            <td></td>                            
                        </tr>
                        <tr align="center">
                            <td colspan="3"></td>
                            <td style="color: #000000; font-weight: bold; background: #add1f7; border: 1px solid #000000; ">TOTAL Q</td>
                            <td class="totalNo" style="font-weight: bold; border: 1px solid #000000;"><b>${this.pipeNumber.transform(
                            prevent.invoice.invoiceTotal,
                            "1.2-2"
                            )}</b></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="saltopagina"></div>
        </body>
        </html>`;

    return (css + html);
  }

  //NOTAS DE TRANSFERENCIA 
    async purchaseNail(purchase, detail, company, purchasejson) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
            @media all {
                div.saltopagina {
                    display: none;
                }
            }
        
            @media print {
                div.saltopagina {
                    display: block;
                    page-break-before: always;
                }
        
                @page {
                    margin: 0;
                    size: auto;
                    transform: scale(1);                      
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }   
                
                html { background: white !important }      
                
                body { height: auto; !important }
            }

            html {
                    background: #e3e3e361;
                    font-family: sans-serif;
                    }

            body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
            }
        
            table {
                width: 100%;
                margin: 0px auto;
                font-size: 0.75rem;
            }
        
            .letter {                  
                margin: 0px auto;
                padding-top: 1rem;
                line-height: 0.85rem;     
            }
        
            .container {
                width: 94%;
                padding: 0.5rem;
                margin: 0px auto;
                position: relative;
                border-radius: 1rem;
                border: 1px solid #000000;
            }
        
            .font-s {
                font-size: 0.6rem;
                font-weight: bold;
            }
        
            .font-b {
                font-size: 1rem;
                font-weight: bold;
            }
        
            .bg-b {
                color: #ffffff;
                font-weight: bold;
                background: #4195f3;
            }
        
            .total {
                color: #ffffff;
                font-weight: bold;        
                background: #4195f3;
                border: 1px solid #000000;        
            }
        
            .totalNo {
                font-weight: bold;
                border: 1px solid #000000;
            }
        
            input[type=checkbox] {
                -webkit-transform: scale(1.2);
            }
        </style>`;

        var html = `
        <!DOCTYPE html>
        <html>
        
        <head>
            <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
            <title>NO. COMPRA: ${purchase.purchaseCode}</title>
        </head>
        
        <body>
            <div class="letter">
                <div class="container">            
                    <table>
                        <tr align="center">
                            <td width="20%">
                                <img src="${company.companyLogoUrl}"
                                    style="max-height: 6rem" />
                            </td>
                            <td width="55%">
                                <b class="font-b">${company.companyName}</b>
                                <br>${company.companyAddress}
                                <br>PBX: ${company.companyPhone}
                                <br class="font-s">web: ${company.companyUrl}
                                <br>NIT: ${company.companyTaxDocument}
                            </td>
                            <td width="25%" class="font-b">
                                <div>NO. COMPRA</div>
                                <div>${purchase.purchaseCode}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr align="center" class="bg-b">
                            <td colspan="9">DATOS DE LA COMPRA</td>
                        </tr>
                        <tr>
                            <td width="10%">INGRESADA POR:</td>
                            <td width="50%">${purchase.createdUser}</td>
                            <td width="10%" align="right">AUTORIZADA POR:</td>
                            <td width="30%">${purchase.authorizedUser == null ? 'N/A' : purchase.authorizedUser}</td>
                        </tr>
                        <tr>
                            <td>FECHA DE INGRESO:</td>
                            <td>${purchase.ingresado}</td>
                            <td align="right">FECHA DE AUTORIZACIÓN:</td>
                            <td>${purchase.autorizado}</td>
                        </tr>
                        <tr>
                            <td>PROVEEDOR:</td>
                            <td>${purchase.proveedor}</td>
                            <td align="right">NO. FACTURA:</td>
                            <td>${purchasejson.header.purchaseInvoice}</td>
                        </tr>
                    </table>
                    <table>
                        <tr align="center" class="bg-b">                              
                            <td width="10%">U. SOL.</td>
                            <td width="30%">DESCRIPCIÓN</td>
                            <td width="10%">P/U</td>
                            <td width="10%">TOTAL</td>
                            <td width="10%">U. REC.</td>
                            <td width="30%">PRODUCTO CARGADO</td>
                        </tr>`;
        let total = 0;

        for (const [i, e] of detail.entries()) {
            total += parseFloat(e.subtotal);
            const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';
            html += `<tr style="min-height: 6rem;">                              
                            <td style="background: ${color}" align="center">${e.purchaseDetailRequestQuantity}</td>
                            <td style="background: ${color}">${e.purchaseDetailDescription}</td>
                            <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.purchaseDetailUnitPrice), '1.2-2')}</td>
                            <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.purchaseDetailPrice), '1.2-2')}</td>
                            <td style="background: ${color}" align="center">${e.inventory.inventoryQuantity}</td>
                            <td style="background: ${color}">${e.inventory.inventoryName}</td>
                        </tr>`;
        };

            html += `</table>
                    </br>
                    </br>
                    <table style="height: 3.5rem;">
                        <tr>
                            <td>OBSERVACIONES:</td>
                            <td>${purchase.purchaseComment}</td>
                        </tr>                   
                    </table>
                </div>
            </div>
            <div class="saltopagina"></div>
        </body>
        </html>`;

        await pdfWindow.document.write(css + html);
    }

    async purchaseNailHistory(purchase, company) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
            @media all {
                div.saltopagina {
                    display: none;
                }
            }
        
            @media print {
                div.saltopagina {
                    display: block;
                    page-break-before: always;
                }
        
                @page {
                    margin: 0;
                    size: auto;
                    transform: scale(1);                      
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }   
                
                html { background: white !important }      
                
                body { height: auto; !important }
            }

            html {
                    background: #e3e3e361;
                    font-family: sans-serif;
                    }

            body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
            }
        
            table {
                width: 100%;
                margin: 0px auto;
                font-size: 0.75rem;
            }
        
            .letter {                  
                margin: 0px auto;
                padding-top: 1rem;
                line-height: 0.85rem;     
            }
        
            .container {
                width: 94%;
                padding: 0.5rem;
                margin: 0px auto;
                position: relative;
                border-radius: 1rem;
                border: 1px solid #000000;
            }
        
            .font-s {
                font-size: 0.6rem;
                font-weight: bold;
            }
        
            .font-b {
                font-size: 1rem;
                font-weight: bold;
            }
        
            .bg-b {
                color: #ffffff;
                font-weight: bold;
                background: #4195f3;
            }
        
            .total {
                color: #ffffff;
                font-weight: bold;        
                background: #4195f3;
                border: 1px solid #000000;        
            }
        
            .totalNo {
                font-weight: bold;
                border: 1px solid #000000;
            }
        
            input[type=checkbox] {
                -webkit-transform: scale(1.2);
            }
        </style>`;

        var html = `
        <!DOCTYPE html>
        <html>
        
        <head>
            <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
            <title>NO. COMPRA: ${purchase.purchaseCode}</title>
        </head>
        
        <body>
            <div class="letter">
                <div class="container">            
                    <table>
                        <tr align="center">
                            <td width="20%">
                                <img src="${company.companyLogoUrl}"
                                    style="max-height: 6rem" />
                            </td>
                            <td width="55%">
                                <b class="font-b">${company.companyName}</b>
                                <br>${company.companyAddress}
                                <br>PBX: ${company.companyPhone}
                                <br class="font-s">web: ${company.companyUrl}
                                <br>NIT: ${company.companyTaxDocument}
                            </td>
                            <td width="25%" class="font-b">
                                <div>NO. COMPRA</div>
                                <div>${purchase.purchaseCode}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr align="center" class="bg-b">
                            <td colspan="9">DATOS DE LA COMPRA</td>
                        </tr>
                        <tr>
                            <td width="10%">INGRESADA POR:</td>
                            <td width="50%">${purchase.createdUser}</td>
                            <td width="10%" align="right">AUTORIZADA POR:</td>
                            <td width="30%">${purchase.authorizedUser == null ? 'N/A' : purchase.authorizedUser}</td>
                        </tr>
                        <tr>
                            <td>FECHA DE INGRESO:</td>
                            <td>${purchase.ingresado}</td>
                            <td align="right">FECHA DE AUTORIZACIÓN:</td>
                            <td>${purchase.autorizado}</td>
                        </tr>
                        <tr>
                            <td>PROVEEDOR:</td>
                            <td>${purchase.proveedor}</td>
                            <td align="right">NO. FACTURA:</td>
                            <td>${purchase.purchaseInvoice}</td>
                        </tr>
                    </table>
                    <table>
                        <tr align="center" class="bg-b">                              
                            <td width="10%">U. SOL.</td>
                            <td width="10%">U. REC.</td>
                            <td width="30%">DESCRIPCIÓN</td>
                            <td width="10%">P/U</td>                          
                            <td width="10%">TOTAL</td>                          
                        </tr>`;
        let total = 0;

        for (const [i, e] of purchase.detail.entries()) {
            total += parseFloat(e.subtotal);
            const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';
            html += `<tr style="min-height: 6rem;">                              
                            <td style="background: ${color}" align="center">${e.purchaseDetailRequestQuantity}</td>
                            <td style="background: ${color}" align="center">${e.purchaseDetailRecieveQuantity}</td>
                            <td style="background: ${color}">${e.purchaseDetailDescription}</td>
                            <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.purchaseDetailUnitPrice), '1.2-2')}</td>
                            <td style="background: ${color}" align="center">${this.pipeNumber.transform(parseFloat(e.purchaseDetailPrice), '1.2-2')}</td>                          
                        </tr>`;
        };

            html += `</table>
                    </br>
                    </br>
                    <table style="height: 3.5rem;">
                        <tr>
                            <td>OBSERVACIONES:</td>
                            <td>${purchase.purchaseComment}</td>
                        </tr>                   
                    </table>
                </div>
            </div>
            <div class="saltopagina"></div>
        </body>
        </html>`;

        await pdfWindow.document.write(css + html);
    }

    async receiptPrint(receipt, logo, status) {

        const pdfWindow = window.open();

        const css = `<style type="text/css">
        @media all {
            div.saltopagina {
                display: none;
            }
        }

        @media print {
            div.saltopagina {
                display: block;
                page-break-before: always;
            }

            @page {
                margin: 0;
                size: auto;
                transform: scale(1);                      
                color-adjust: exact;
                -webkit-print-color-adjust: exact;
            }   
            
            html { background: white !important }      
            
            body { height: auto; !important }
        }

        html {
            background: #e3e3e361;
            font-family: sans-serif;
        }

        
        
        .clearfix:after {
            content: "";
            display: table;
            clear: both;
        }
        
        a {
            color: #0087C3;
            text-decoration: none;
        }
        
        body {
            min-height:  5in;
            margin: 10px auto;
            position: relative;
            width: 19cm;
            height: auto; 
            color: #555555;
            background: #FFFFFF; 
            font-family: Arial, sans-serif; 
            font-size: 14px; 
            font-family: SourceSansPro;
            border: solid;
            padding: 25px;
        }
        
        header {
            padding: 10px 0;
            margin-bottom: 20px;
            border-bottom: 1px solid #AAAAAA;
        }
        
        #logo {
            float: left;
            margin-top: 8px;
        }
        
        #logo img {
            height: 70px;
        }
        
        #company {
            float: right;
            text-align: right;
        }
        
        
        #details {
            margin-bottom: 50px;
        }
        
        #client {
            padding-left: 6px;
            border-left: 6px solid #0087C3;
            float: left;
        }
        
        #client .to {
            color: #777777;
        }
        
        h2.name {
            font-size: 1.4em;
            font-weight: normal;
            margin: 0;
        }
        
        #invoice {
            float: right;
            text-align: right;
        }
        
        #invoice h1 {
            color: #0087C3;
            font-size: 1.8em;
            line-height: 1em;
            font-weight: normal;
            margin: 0  0 10px 0;
        }
        
        #invoice .date {
            font-size: 1.1em;
            color: #777777;
        }
        
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            margin-bottom: 20px;
        }
        
        table th,
        table td {
            padding: 20px;
            background: #EEEEEE;
            text-align: center;
            border-bottom: 1px solid #FFFFFF;
        }
        
        table th {
            white-space: nowrap;        
            font-weight: normal;
        }
        
        table td {
            text-align: right;
        }
        
        table td h3{
            color: #0087c3;
            font-size: 1.2em;
            font-weight: normal;
            margin: 0 0 0.2em 0;
        }
        
        table .no {
            color: #FFFFFF;
            font-size: 0.8em;
            background: #0087c3;
        }
        
        table .desc {
            text-align: left;
        }
        
        table .unit {
            background: #DDDDDD;
        }
        
        table .qty {
        }
        
        table .total {
            background: #0087c3;
            color: #FFFFFF;
        }
        
        table td.unit,
        table td.qty,
        table td.total {
            font-size: 0.9em;
        }
        
        table tbody tr:last-child td {
            border: none;
        }
        
        table tfoot td {
            padding: 10px 20px;
            background: #FFFFFF;
            border-bottom: none;
            font-size: 1.2em;
            white-space: nowrap; 
            border-top: 1px solid #AAAAAA; 
        }
        
        table tfoot tr:first-child td {
            border-top: none; 
        }
        
        table tfoot tr:last-child td {
            color: #0087c3;
            font-size: 1.4em;
            border-top: 1px solid #0087c3; 
        
        }
        
        table tfoot tr td:first-child {
            border: none;
        }
        
        #thanks{
            font-size: 2em;
            margin-bottom: 50px;
        }
        
        #notices{
            padding-left: 6px;
            border-left: 6px solid #0087C3;  
        }
        
        #notices .notice {
            font-size: 1.2em;
        }
        
        footer {
            color: #777777;
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 0;
            border-top: 1px solid #AAAAAA;
            padding: 8px 0;
            text-align: center;
        }

        </style>`;


        var html = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="utf-8">
            <title>Recibo No. </title>
        </head>
        <body>
            <header class="clearfix">
            <div id="logo">
                <img src="${logo}" alt="..." style="width: 7rem;height: 7rem;">
                <p>${status == 1 ? '' : 'ANULADO'}</p>
            </div>
            <div id="company">
                <h2 class="name">${receipt.transmitter.transmitterName}</h2>
                <div>${receipt.transmitter.transmitterAddress}</div>
                <div><img src="../assets/img/WhatsApp-logo.png" style="width: 20px;"/>${receipt.transmitter.transmitterPhone}</div>
                <div><a href="mailto:${receipt.transmitter.transmitterEmail}">${receipt.transmitter.transmitterEmail}</a></div>
            </div>
            </div>
            </header>
            <main>
            <div id="details" class="clearfix">
                <div id="client">
                <div class="to">RECIBÍ DE:</div>
                <h2 class="name">${receipt.receiver.receiverName}</h2>
                <div class="address">${receipt.receiver.receiverTaxDocument}</div>
                <div class="address">${receipt.receiver.receiverAddress}</div>
                <div class="email"><a href="mailto:${receipt.receiver.receiverEmail}">${receipt.receiver.receiverEmail}</a></div>
                </div>
                <div id="invoice">
                <h1>Recibo Serie: -${receipt.invoice.invoiceInternSerial}- No. ${receipt.invoice.invoiceInternNumber}</h1>
                <div class="date">Fecha Emisión: ${receipt.invoice.invoiceDate}</div>
                <div class="date">Autorización: ${receipt.invoice.invoiceCode}</div>
                </div>
            </div>
            <table border="0" cellspacing="0" cellpadding="0">
                <thead>
                <tr>
                    <th class="total">CANTIDAD</th>
                    <th class="total">DESCRIPCIÓN</th>
                    <th class="total">PRECIO UNIT.</th>                
                    <th class="total">TOTAL</th>
                </tr>
                </thead>
                <tbody>`;

                for (const [i, e] of receipt.detail.entries()) {                
                    const color = (i % 2 != 0) ? '#dfdfdf' : '#ffffff';
                    html += `   <tr>
                                    <td class="qty">${e.quantity}</td>
                                    <td class="desc">${e.description}</td>
                                    <td class="unit">${receipt.invoice.invoiceCurrency}. ${e.unitPrice}</td>                                
                                    <td class="total">${receipt.invoice.invoiceCurrency}. ${e.total}</td>
                                </tr>`;
                };

                html += `
                </tbody>
                <tfoot>
                <tr>                
                    <td colspan="3">TOTAL</td>
                    <td>${receipt.invoice.invoiceCurrency}. ${receipt.invoice.invoiceTotal}</td>
                </tr>
                </tfoot>
            </table>
            <div id="thanks">¡Gracias por tu Compra!</div>
            <div id="notices">
                <div>NOTICE:</div>
                <div class="notice">El presente documento NO ES UNA FACTURA.</div>
            </div>
            </main>
            <!--  <footer>
            Clever Software.
            </footer> -->
        </body>
        </html>
        `;

        await pdfWindow.document.write(css + html);

    }

    async paymentInvoiceHistory(detail, company, totalPayment, saldox) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
            @media all {
                div.saltopagina {
                    display: none;
                }
            }
        
            @media print {
                div.saltopagina {
                    display: block;
                    page-break-before: always;
                }
        
                @page {
                    margin: 0;
                    size: auto;
                    transform: scale(1);                      
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }   
                
                html { background: white !important }      
                
                body { height: auto; !important }
            }

            html {
                    background: #e3e3e361;
                    font-family: sans-serif;
                    }

            body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
            }
        
            table {
                width: 100%;
                margin: 0px auto;
                font-size: 0.75rem;
            }
        
            .letter {                  
                margin: 0px auto;
                padding-top: 1rem;
                line-height: 0.85rem;     
            }
        
            .container {
                width: 94%;
                padding: 0.5rem;
                margin: 0px auto;
                position: relative;
                border-radius: 1rem;
                border: 1px solid #000000;
            }
        
            .font-s {
                font-size: 0.6rem;
                font-weight: bold;
            }
        
            .font-b {
                font-size: 1rem;
                font-weight: bold;
            }
        
            .bg-b {
                color: #ffffff;
                font-weight: bold;
                background: #4195f3;
            }
        
            .total {
                color: #ffffff;
                font-weight: bold;        
                background: #4195f3;
                border: 1px solid #000000;        
            }
        
            .totalNo {
                font-weight: bold;
                border: 1px solid #000000;
            }
        
            input[type=checkbox] {
                -webkit-transform: scale(1.2);
            }

            .payRegisterList {
                border-bottom: 3px solid #748a97;
                margin-bottom: 1rem;
                padding-right: 20%;
                display: inline-block;
            }
            
            .itemBlock{
                padding-top: 10px;
                border: 0px solid #ececec94;
                display: flex;
            }
        </style>`;

        var html = `
        <!DOCTYPE html>
        <html>
        
        <head>
            <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
        </head>
        
        <body>
            <div class="letter">
                <div class="container">            
                    <table>
                        <tr align="center">
                            <td width="20%">
                                <img src="${company.companyLogoUrl}"
                                    style="max-height: 6rem" />
                            </td>
                            <td width="55%">
                                <b class="font-b">${company.companyName}</b>
                                <br>${company.companyAddress}
                                <br>PBX: ${company.companyPhone}
                                <br class="font-s">web: ${company.companyUrl}
                                <br>NIT: ${company.companyTaxDocument}
                            </td>                        
                        </tr>
                    </table>

                    <br/><hr/>

                    <h4>Registro de Pagos</h4>
                    <hr/>

                    <div class="col-sm-11">`;

        let total = 0;

        for (const [i, e] of detail.entries()) {
            total = parseFloat(e.total);
            html += `<div class="row payRegisterList" *ngFor="let e of payRegister, let i=index">
                        <div class="col-md-3 itemBlock">
                        <b class="text-info">Pago ${i+1} de ${detail.length}</b>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Factura</label>
                        <p>${ e.factura }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Fecha</label>
                        <p>${ e.payRegisterDate }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Monto</label>
                        <p>Q ${ e.payRegisterAmount } </p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Método de pago</label>
                        <p>${ e.paymentMethodName }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>No. Documento</label>
                        <p>${ e.payRegisterDocument }</p>
                        </div>
                        <div class="col-md-9 itemBlock">
                        <label>Descripción</label>
                        <p>${ e.payRegisterDescription }</p>
                        </div>
                    </div>`;       
        };

            html += `
                    <hr/>  
                    <b class="text-info">Total Pagos Q.${totalPayment} </b><br/><br/>
                    <b class="text-info">Saldo Q.${saldox} </b><br/><br/>
                    <b class="text-info">Valor de Cuenta Q.${total} </b><br>
                    </div>
                    </br>
                    </br>                
                </div>
            </div>
            <div class="saltopagina"></div>
        </body>
        </html>`;

        await pdfWindow.document.write(css + html);
    }

    async paymentPurchaseHistory(detail, company, totalPayment, saldox) {
        const pdfWindow = window.open();
        const css = `<style type="text/css">
            @media all {
                div.saltopagina {
                    display: none;
                }
            }
        
            @media print {
                div.saltopagina {
                    display: block;
                    page-break-before: always;
                }
        
                @page {
                    margin: 0;
                    size: auto;
                    transform: scale(1);                      
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }   
                
                html { background: white !important }      
                
                body { height: auto; !important }
            }

            html {
                    background: #e3e3e361;
                    font-family: sans-serif;
                    }

            body {
                width: 8.5in;
                height: auto; /*11in;*/
                min-height:  5in;
                margin: 0px auto;
                background: white; 
            }
        
            table {
                width: 100%;
                margin: 0px auto;
                font-size: 0.75rem;
            }
        
            .letter {                  
                margin: 0px auto;
                padding-top: 1rem;
                line-height: 0.85rem;     
            }
        
            .container {
                width: 94%;
                padding: 0.5rem;
                margin: 0px auto;
                position: relative;
                border-radius: 1rem;
                border: 1px solid #000000;
            }
        
            .font-s {
                font-size: 0.6rem;
                font-weight: bold;
            }
        
            .font-b {
                font-size: 1rem;
                font-weight: bold;
            }
        
            .bg-b {
                color: #ffffff;
                font-weight: bold;
                background: #4195f3;
            }
        
            .total {
                color: #ffffff;
                font-weight: bold;        
                background: #4195f3;
                border: 1px solid #000000;        
            }
        
            .totalNo {
                font-weight: bold;
                border: 1px solid #000000;
            }
        
            input[type=checkbox] {
                -webkit-transform: scale(1.2);
            }

            .payRegisterList {
                border-bottom: 3px solid #748a97;
                margin-bottom: 1rem;
                padding-right: 20%;
                display: inline-block;
            }
            
            .itemBlock{
                padding-top: 10px;
                border: 0px solid #ececec94;
                display: flex;
            }
        </style>`;

        var html = `
        <!DOCTYPE html>
        <html>
        
        <head>
            <link rel="shortcut icon" href="https://ima-design-images.s3.amazonaws.com/IMERP/clever-icon.png" type="image/x-icon">
        </head>
        
        <body>
            <div class="letter">
                <div class="container">            
                    <table>
                        <tr align="center">
                            <td width="20%">
                                <img src="${company.companyLogoUrl}"
                                    style="max-height: 6rem" />
                            </td>
                            <td width="55%">
                                <b class="font-b">${company.companyName}</b>
                                <br>${company.companyAddress}
                                <br>PBX: ${company.companyPhone}
                                <br class="font-s">web: ${company.companyUrl}
                                <br>NIT: ${company.companyTaxDocument}
                            </td>                        
                        </tr>
                    </table>

                    <br/><hr/>

                    <h4>Registro de Pagos</h4>
                    <hr/>

                    <div class="col-sm-11">`;

        let total = 0;

        for (const [i, e] of detail.entries()) {
            total = parseFloat(e.purchaseTotal);
            html += `<div class="row payRegisterList" *ngFor="let e of payRegister, let i=index">
                        <div class="col-md-3 itemBlock">
                        <b class="text-info">Pago ${i+1} de ${detail.length}</b>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Factura</label>
                        <p>${ e.payRegisterPurchaseCode }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Fecha</label>
                        <p>${ e.payRegisterDate }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Monto</label>
                        <p>Q ${ e.payRegisterAmount } </p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>Método de pago</label>
                        <p>${ e.paymentMethodName }</p>
                        </div>
                        <div class="col-md-3 itemBlock">
                        <label>No. Documento</label>
                        <p>${ e.payRegisterDocument }</p>
                        </div>
                        <div class="col-md-9 itemBlock">
                        <label>Descripción</label>
                        <p>${ e.payRegisterDescription }</p>
                        </div>
                    </div>`;       
        };

            html += `
                    <hr/>  
                    <b class="text-info">Total Pagos Q.${totalPayment} </b><br/><br/>
                    <b class="text-info">Saldo Q.${saldox} </b><br/><br/>
                    <b class="text-info">Valor de Cuenta Q.${total} </b><br>
                    </div>
                    </br>
                    </br>                
                </div>
            </div>
            <div class="saltopagina"></div>
        </body>
        </html>`;

        await pdfWindow.document.write(css + html);
    }

    async accountStatement(company, headHtml, headerHtml, detailHtml) {

        const pdfWindow = window.open();

        const cssSytles = `<style type="text/css">        
            @media print {
                @page {
                    margin: 1.48cm;;
                    size: auto;   
                    transform: scale(1);              
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                }   
                
                html { background: white !important }      
                
                body { height: auto; !important }
            }

            html {
                background: #e3e3e361;
                font-family: sans-serif;
            }

            body {
                width: 8.5in;
                height: auto;
                min-height:  5in;
                margin: 0px auto;
                background: white; 
            }
        
            table {
                width: 100%;
                margin: 0px auto;
                font-size: 0.75rem;
            }

            .container {
                width: 100%;
                padding: 0.5rem;
                margin: 0px auto;
                position: relative;
                border-radius: 1rem;
                border: 1px solid #000000;
            }
        
            .font-s {
                font-size: 0.6rem;
                font-weight: bold;
            }
        
            .font-b {
                font-size: 1rem;
                font-weight: bold;
            }
        
            .bg-b {
                color: #ffffff;
                font-weight: bold;
                background: #4195f3;
            }

            .payRegisterList {
                border-bottom: 3px solid #748a97;
                margin-bottom: 1rem;
            }
            
            .itemBlock{
                padding-top: 10px;
                border: 0px solid #ececec94;
            }

            #print-none-button {
                display: none;
            }
            :host ::ng-deep .ngx-datatable .datatable-body .datatable-scroll {
                display: inherit;
            }
        </style>`;

        var html = `
        <!DOCTYPE html>
        <html>
            <head>
                ${headHtml}'
                ${cssSytles}
            </head>
            <body>
                <div class="letter my-4">
                    <div class="container"> 
                        <div class="card-body d-print-none">
                            <div class="row justify-content-center">
                                <div class="col-11 font-weight-bold">
                                    Estado de cuenta
                                </div>
                                <div class="col-4 text-right" *ngIf="isViewBeginning">
                                    <a class="btn btn-warning btn-block btn-gen" role="button" onclick="printDocument()">
                                        <i class="now-ui-icons files_paper"></i> Imprimir
                                    </a>
                                </div>
                            </div>
                        </div>          
                        <table>
                            <tr class="text-center">
                                <td width="20%">
                                    <img src="${company.companyLogoUrl}"
                                        style="max-height: 6rem" />
                                </td>
                                <td width="55%">
                                    <b class="font-b">${company.companyName}</b>
                                    <br>${company.companyAddress}
                                    <br>PBX: ${company.companyPhone}
                                    <br class="font-s">web: ${company.companyUrl}
                                    <br>NIT: ${company.companyTaxDocument}
                                </td>                        
                            </tr>
                        </table>
                        <br/><hr/>
                        <div class="col-sm-12">
                            ${headerHtml}
                            <div class="card">
                                <div class="card-body">
                                    <div class="dataTables_wrapper">
                                        ${detailHtml}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <script>
                    function printDocument() {
                        window.print();
                    }
                </script>
            </body>
        </html>`;

        await pdfWindow.document.write(html);
        pdfWindow.print();
    }

}