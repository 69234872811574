<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/bank-account">Cuentas Bancarias</a>
        </div>
    </div>
</div>

<div class="main-content">

    <div class="row justify-content-center">
        <div class="col-md-12">
            <form #formulario="ngForm">
                <div class="card">
                    <div class="card-header ">
                        <div class="row">
                            <div class="col-10">
                                <b class="card-title" *ngIf="!isEdit">Nueva Cuenta Bancaria </b>
                                <b class="card-title text-warning" *ngIf="isEdit">Modificar Cuenta Bancaria </b>
                            </div>
                            <div class="col-2 float-right" *ngIf="isEdit"> 
                                <a class="btn btn-danger btn-icon btn-round "
                                    (click)="cleanModel()"><i class="fas fa-times"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <label>
                                    Nombre 
                                    <span class="text-danger" *ngIf="validate(1, 'name')">{{requiredText}}</span>
                                </label>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name"
                                        [(ngModel)]="bankAccountModel.name" required />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>
                                    No. Cuenta 
                                    <span class="text-danger" *ngIf="validate(1, 'number')">{{requiredText}}</span>
                                </label>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="number"
                                        [(ngModel)]="bankAccountModel.number" [disabled]='isEdit' required />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>
                                    Tipo 
                                    <span class="text-danger" *ngIf="validate(1, 'typeAccountList')">{{requiredText}}</span>
                                </label>
                                <select name="typeAccountList" aria-controls="typeAccountList" class="form-control"
                                    [(ngModel)]="bankAccountModel.type" [disabled]='isEdit' required>
                                    <option value="SAVING">Ahorro</option>
                                    <option value="MONETARY">Monetaria</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>
                                    Banco 
                                    <span class="text-danger" *ngIf="validate(1, 'bankList')">{{requiredText}}</span>
                                </label>
                                <select name="bankList" aria-controls="bankList" class="form-control"
                                    [(ngModel)]="bankAccountModel.bank" [disabled]='isEdit' required>
                                    <option [value]="bank.id_bank" *ngFor="let bank of bankList">{{bank.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>
                                    Moneda 
                                    <span class="text-danger" *ngIf="validate(1, 'currencyList')">{{requiredText}}</span>
                                </label>
                                <select name="currencyList" aria-controls="currencyList" class="form-control"
                                    [(ngModel)]="bankAccountModel.currency" [disabled]='isEdit' required>
                                    <option [value]="currency.id_currency" *ngFor="let currency of currencyList">{{currency.symbol}} - {{currency.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label></label>
                                <div class="form-group">
                                    <button class="btn btn-info btn-round btn-erp" (click)="save()" *ngIf="!isEdit"
                                        [disabled]="isDisabled || formulario.invalid">Guardar</button>
                                    <button class="btn btn-primary btn-block btn-erp" (click)="update()" *ngIf="isEdit"
                                        [disabled]="isDisabled || formulario.invalid">Actualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de cuentas bancarias</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search
                        records"</h6>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Nombre" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column name="No. Cuenta" prop="number"></ngx-datatable-column>
                            <ngx-datatable-column name="Tipo" prop="type_desc"></ngx-datatable-column>
                            <ngx-datatable-column name="Banco" prop="bank"></ngx-datatable-column>
                            <ngx-datatable-column name="Moneda" prop="currency"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm edit"
                                        (click)="loadFuntion($event)" ngbTooltip="Estado de cuenta" >
                                        <i class="now-ui-icons files_paper"></i>
                                    </a>      
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="editFunction($event)" ngbTooltip="Editar">
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar">
                                        <i class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>