import { HttpClient, HttpHeaders } from '@angular/common/http'; //eliminar
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '../models/store';
import { Client } from '../models/client';
import { Company } from '../models/company';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class XmlService {
    pipeDate = new DatePipe('en-US');
    public url: string;
    public urlIMFEL: string;
    public client: Client = {} as Client;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
        this.urlIMFEL = environment.apiIMFEL;
    }

    getClient(nit: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'imerbackend-client/taxDocument/company/' + nit + '/' + company, { headers: headers }).toPromise();
    }

    // searchSAT(nit: string, type: string): Promise<any> {
    //     const headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this._http.get(this.urlIMFEL + nit + '/' + type, { headers: headers }).toPromise();
    // }    

    searchSAT(nit,type): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        // return this._http.get(this.urlIMFEL + nit + '/' + type, { headers: headers }).toPromise();
        return this._http.get('https://53jp2f6475.execute-api.us-east-1.amazonaws.com/pro/imfeltax-taxDocument/' + nit + '/' + type, { headers: headers }).toPromise();
    }

    insertClient(client: Client): Promise<any> {
        const params = JSON.stringify(client);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-client', params, { headers: headers }).toPromise();
    }

    searchInvoice(serie: string, invoice: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/complement/${serie}/${invoice}`, { headers: headers }).toPromise();
    }

    searchDocument(document): Promise<any> {
        const params = JSON.stringify(document);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + `imerbackend-document`, params, { headers: headers }).toPromise();
    }

    public header: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    xmlInvoiceExchange(invoiceCode: string): Promise<any> {
        return this._http.get(this.url + `imerbackend-invoiceExchange/xml/one/${invoiceCode}`, { headers: this.header }).toPromise();
    }
    
    xmlCreditNote(creditNoteCode: string): Promise<any> {
        return this._http.get(this.url + `imerbackend-creditNote/xml/one/${creditNoteCode}`, { headers: this.header }).toPromise();
    }


    getAllSale(begDate: string, endDate: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/report/${begDate}/${endDate}/${company}`, { headers: headers }).toPromise();
    }

    getAllBook(begDate: string, endDate: string, company: number): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/report/attendBooks/${begDate}/${endDate}/${company}`, { headers: headers }).toPromise();
    }

    async replaceCharter(name: string) {
        name = await name.replace(/&/g, 'Y');
        name = await name.replace(/'/g, '');
        name = await name.replace(/"/g, '');
        return name;
    }

    dateES_US(date, split) {
        const date_split = date.split(split);
        const convertedDate = `${date_split[2]}-${date_split[1]}-${date_split[0]}`;
        return convertedDate;
    }

    async clientValidation(taxDocument: string, companyCode: number, address: string, type: string, name: string) {
        const client = await this.getClient(taxDocument, companyCode);

        if(type === "2"){
            if (!client.records[0]) {
                this.client.clientName = name;
                this.client.clientAddress = address;
                this.client.clientEmail = "info@sylvania.com";
                this.client.clientCompanyCode = companyCode;
                this.client.clientTaxDocument = taxDocument;
                this.client.clientPhone = "0000-0000";
                this.client.clientCreditAmount = 0;
                this.client.clientCreditTime = 0;
                this.client.clientCategory = 2;
                this.client.clientInterest = 0;
                this.client.clientDiscount = 0;
                this.client.clientDeleted = 0;
                this.client.clientBlocked = 0;
                this.client.clientCredit = 0;
                this.client.clientStatus = 1;
                this.client.clientType = 2;
                this.client.clientNameFrequent = '-';

                const insertClient = await this.insertClient(this.client);
    
                    if (insertClient.records[0].insertId) {
                        return {
                            clientEmail: this.client.clientEmail,
                            clientCode: insertClient.records[0].insertId,
                            clientAddress: this.client.clientAddress,
                            clientName: this.client.clientName,
                        };
                    } else {
                        return false; 
                    }


            } else {
                return client.records[0];
            }

        }
        else{
            if (!client.records[0]) {                
                const newclient = await this.searchSAT(taxDocument, type);

                console.log(newclient);
    
                if (newclient.tipo_respuesta[0] === '0') {
                    this.client.clientName = await this.replaceCharter(newclient.nombre[0]);
                    this.client.clientAddress = newclient.hasOwnProperty('direccion') ? newclient.direcciones[0].direccion[0] : address;
                    this.client.clientEmail = "info@sylvania.com";
                    this.client.clientCompanyCode = companyCode;
                    this.client.clientTaxDocument = taxDocument;
                    this.client.clientPhone = "0000-0000";
                    this.client.clientCreditAmount = 0;
                    this.client.clientCreditTime = 0;
                    this.client.clientCategory = 2;
                    this.client.clientInterest = 0;
                    this.client.clientDiscount = 0;
                    this.client.clientDeleted = 0;
                    this.client.clientBlocked = 0;
                    this.client.clientCredit = 0;
                    this.client.clientStatus = 1;
                    this.client.clientType = 0;
                    this.client.clientNameFrequent = '-';
    
                    const insertClient = await this.insertClient(this.client);
    
                    if (insertClient.records[0].insertId) {
                        return {
                            clientEmail: this.client.clientEmail,
                            clientCode: insertClient.records[0].insertId,
                        };
                    } else {
                        return false; 
                    }
                } else {
                    let SATerror = {
                        error: true,
                        DetalleError: newclient.listado_errores[0].error[0].cod_error + ' -> ' + newclient.listado_errores[0].error[0].desc_error + ' --> CUI no válido',
                    }
                    return SATerror; 
                }
            } else {
                return client.records[0];
            }
        }        
    }

    async convertXMLtoJSON(dte: string, walmart: boolean, xmltxt: any, userCode: number, company: Company, store: Store, base64: string) {
        const xml = (new DOMParser()).parseFromString(xmltxt, "text/xml");
        const validationDTE = xml.getElementsByTagName('LOTE')[0]?.getElementsByTagName('DOCUMENTOS')[0]?.getElementsByTagName(dte)[0]?.tagName;
        if (validationDTE) {
            const xml_detalle = xml.getElementsByTagName('LOTE')[0].getElementsByTagName('DOCUMENTOS')[0].getElementsByTagName(dte)[0]?.getElementsByTagName('DETALLE')[0];
            const longitud = xml_detalle.childElementCount;

            console.log('Frases en función',company.phrase);
            let typeClientXML_Ext = xml.querySelector('EXT');
            let Exenta = false;            
            
            if(typeClientXML_Ext !== null){
                console.log('EXT');
                company.phrase.push({phraseStage: '1', phrase: '2'});
                company.phrase.push({phraseStage: '1', phrase: '4'});
                console.log(company.phrase);
                Exenta = true;
            }
            else{
                console.log('NO EXT');
                console.log(company.phrase);
            }

            let DETALLE: any[] = new Array();
            for (let i = 0; i < longitud; i++) {

                let taxableAmount;
                let taxAmount;
                let taxCode = 1;

                if(Exenta){
                    taxableAmount = (parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue));
                    taxAmount = (parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue) / 1.12) * 0;
                    taxCode = 2;
                }
                else{
                    taxableAmount = (parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue) / 1.12);
                    taxAmount = (parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue) / 1.12) * 0.12;
                }                
               
                const LINEA = {
                    taxCode: taxCode,
                    itemCode: 0,
                    discount: 0,
                    measure: 'UNI',
                    taxName: 'IVA',
                    taxAmount: parseFloat(taxAmount.toFixed(6)),
                    taxableAmount: parseFloat(taxableAmount.toFixed(6)),
                    exempt: xml_detalle.children[i].getElementsByTagName('EXENTO')[0].childNodes[0]?.nodeValue,
                    quantity: xml_detalle.children[i].getElementsByTagName('CANTIDAD')[0].childNodes[0]?.nodeValue,
                    total: parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue),
                    description: (xml_detalle.children[i].getElementsByTagName('DESCRIPCION')[0]?.textContent).trim(),
                    price: parseFloat(xml_detalle.children[i].getElementsByTagName('VALOR')[0].childNodes[0]?.nodeValue),
                    unitPrice: xml_detalle.children[i].getElementsByTagName('PRECIOUNITARIO')[0].childNodes[0]?.nodeValue,
                    isService: xml_detalle.children[i].getElementsByTagName('TIPO_PRODUCTO')[0].childNodes[0]?.nodeValue === 'BIEN' ? 'B' : 'S',
                    addendum: {
                        valor1: xml_detalle.children[i].getElementsByTagName('DETALLE1')[0].childNodes[0]?.nodeValue
                    }
                }
                DETALLE.push(LINEA);
            }

            if (dte === 'NOTACREDITO') {
                const xml_ncredito = xml.getElementsByTagName('LOTE')[0].getElementsByTagName('DOCUMENTOS')[0].getElementsByTagName(dte)[0]?.getElementsByTagName('ENCABEZADO')[0];


                let document = {
                    document: xml_ncredito.getElementsByTagName('NODOCUMENTO')[0].childNodes[0]?.nodeValue,
                    type: "2"
                };

                const searchDocument = await this.searchDocument(document); //Validar si ya se firmo el documento
                if(searchDocument.records.length > 0){
                    let jsonerror = {
                        errorDocument: true,
                        errorDetail: "Documento ya fue generado"
                    }
                    return jsonerror;
                }

                let typeClientXML = xml_ncredito.querySelector('NITCONTRIBUYENTE');
                let typeClient = "0";
                let nit = "";
                let Total = 0;
                
                if(typeClientXML !== null){
                    typeClient = "0";
                    console.log('Cliente Tipo ->', typeClient);
                    nit = xml_ncredito.getElementsByTagName('NITCONTRIBUYENTE')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                }
                else{
                    let typeClientXML_C = xml_ncredito.querySelector('CUI');
                    
                    if(typeClientXML_C !== null){
                        typeClient = "1";
                        console.log('Cliente Tipo ->',typeClient);
                        nit = xml_ncredito.getElementsByTagName('CUI')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                        if (nit.length < 13){
                            let jsonerror = {
                                errorClient: true,
                                errorDetail: "CUI no cumple con la longitud autorizada"
                            }
                            return jsonerror;
                        }
                    }
                    else{
                        let typeClientXML_E = xml_ncredito.querySelector('EXT');
                    
                        if(typeClientXML_E !== null){
                            typeClient = "2";
                            console.log('Cliente Tipo ->',typeClient);
                            nit = xml_ncredito.getElementsByTagName('EXT')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                        }
                        else{
                            console.log('NO EXISTE');
                            return false;
                        }
                    }
                }

                Total = parseFloat(xml_ncredito.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue);
                console.log('Identificador->',nit);
                console.log('Total->',Total);

                if(Total >= 2500){
                    console.log('Validar CF');
                    
                    if(nit === "CF"){
                        return true;
                    }
                }
                else{
                    console.log('OK CF');
                }

                let monedaNC = xml_ncredito.getElementsByTagName('MONEDA')[0].childNodes[0]?.nodeValue;

                let creditNoteTypex = "GTQ";
                //Nota de credito excenta en dolares
                if(monedaNC == "USD"){
                    company.phrase = [];
                    company.phrase.push({phraseStage: '1', phrase: '1'});
                    company.phrase.push({phraseStage: '1', phrase: '4'});
                    //company.phrase.push({phraseStage: '1', phrase: '4'});
                    //company.phrase.push({phraseStage: '4', phrase: '1'});
                    
                    console.log('NC en Dolares', monedaNC);
                    console.log('NC en Dolares Frases', company.phrase);
                    console.log('Phrase ->', company.phrase);

                    creditNoteTypex = "USD";

                }

                //const nit = xml_factura.getElementsByTagName('NITCONTRIBUYENTE')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');                
                const address = (xml_ncredito.getElementsByTagName('DIRECCIONCONTRIBUYENTE')[0]?.textContent).trim();
                const name = (xml_ncredito.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim();
                                
                const client = await this.clientValidation(nit, company.companyCode, address, typeClient, name);                

                if(client.hasOwnProperty('error')){
                    let jsonerror = {
                        errorClient: true,
                        errorDetail: client.DetalleError
                    }
                    return jsonerror;
                }

                if (client.error) { return false; };                


                const searchInvoiceExchange = await this.searchInvoice(xml_ncredito.getElementsByTagName('SERIEFACTURA')[0].childNodes[0]?.nodeValue, xml_ncredito.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue);
                if (!searchInvoiceExchange || searchInvoiceExchange.records.length == 0) { return false; };

                const xml_opcional = xml.getElementsByTagName('LOTE')[0].getElementsByTagName('DOCUMENTOS')[0]?.getElementsByTagName('OPCIONAL')[0];

                const ADENDASUMMARY = {
                    valor1: '',
                    valor2: '',
                    valor3: '',
                    valor4: '',
                    valor5: '',
                    valor6: '',
                    valor7: '',
                    valor8: '',
                    valor9: '',
                    valor10: '',
                    valor11: '',
                    valor12: '',
                    valor13: '',
                    valor14: '',
                    valor15: '',
                    valor18: '',
                    valor16: xml_opcional.getElementsByTagName('OPCIONAL6')[0].childNodes[0].nodeValue,
                    valor17: xml_opcional.getElementsByTagName('OPCIONAL7')[0].childNodes[0].nodeValue,
                    valor19: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0].nodeValue,
                    valor20: xml_opcional.getElementsByTagName('OPCIONAL2')[0].childNodes[0].nodeValue,
                    valor21: xml_opcional.getElementsByTagName('OPCIONAL4')[0].childNodes[0].nodeValue,
                    valor22: xml_opcional.getElementsByTagName('OPCIONAL3')[0].childNodes[0].nodeValue,
                    valor23: xml_opcional.getElementsByTagName('OPCIONAL5')[0].childNodes[0].nodeValue
                };
                
                const today = new Date();
                const creditNoteDate = await this.pipeDate.transform(today, 'yyy-MM-dd HH:mm:ss');

                return {
                    store: {
                        storeCode: store.storeCode
                    },
                    user: {
                        userCode: userCode
                    },
                    transmitter: {
                        transmitterType: 'NCRE',
                        transmitterCountry: 'GT',
                        transmitterMembership: 'GEN',
                        transmitterZipCode: store.storeZipCode,
                        transmitterEmail: company.companyEmail,
                        transmitterTaxDocument: company.companyTaxDocument,
                        transmitterEstablishmentCode: store.storeEstablishmentCode,
                        transmitterName: company.companyName.toUpperCase(),
                        transmitterAddress: store.storeAddress.toUpperCase(),
                        transmitterComercialName: store.storeName.toUpperCase(),
                        transmitterDepartment: store.storeDepartment.toUpperCase(),
                        transmitterMunicipality: store.storeMunicipality.toUpperCase(),
                        transmitterKey: company.companyKey,
                        transmitterToken: company.companyToken,
                        transmitterUser: company.companyPrefix,
                        transmitterPrefix: company.companyPrefix,
                        transmitterPhraseCode: parseInt(company.companyPhraseCode),
                        transmitterPhraseType: parseInt(company.companyPhraseType)
                    },
                    receiver: {
                        receiverAddress: address, 
                        receiverCode: client.clientCode,
                        receiverCountry: 'GT',
                        receiverDeleted: 0,
                        receiverEmail: client.clientEmail,
                        receiverName: client.clientName, //(xml_ncredito.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(), //xml_ncredito.getElementsByTagName('NOMBRECONTRIBUYENTE')[0].childNodes[1]?.nodeValue,
                        receiverStatus: 1,
                        receiverTaxDocument: nit,
                        receiverTypeTaxDocument: typeClient, 
                        receiverZipCode: 0
                    },
                    invoice: {
                        invoiceCode: 0,
                        invoiceInternSerial: 'A',
                        invoicepaymentType: 0
                    },
                    creditNote: {
                        creditNoteCode: 0,
                        creditNoteInternSerial: 'A',
                        creditNoteTaxShortName: "IVA",
                        creditNoteTotal: parseFloat(xml_ncredito.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue),
                        creditNoteTaxValue: parseFloat(xml_ncredito.getElementsByTagName('IVA')[0].childNodes[0]?.nodeValue),
                        creditNoteComplementId: "Notas", //1
                        creditNoteComplementName: "Notas", //NOTA CREDITO

                        creditNoteSourceDocumentIssuanceDate: searchInvoiceExchange.records[0]?.invoiceExchangeDate.substring(0, 10),
                        creditNoteOrigialDocumentAuthorizationNumber: searchInvoiceExchange.records[0]?.invoiceExchangeAuthorization,
                        creditNoteOrigialDocumentNumber: xml_ncredito.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue,
                        creditNoteOrigialDocumentSerie: xml_ncredito.getElementsByTagName('SERIEFACTURA')[0].childNodes[0]?.nodeValue,

                        creditNoteFitMotif: xml_ncredito.getElementsByTagName('CONCEPTO')[0].childNodes[0]?.nodeValue,
                        creditNoteConcept: 3, 
                        creditNoteDate: creditNoteDate,
                        creditNoteCurrency: xml_ncredito.getElementsByTagName('MONEDA')[0].childNodes[0]?.nodeValue,
                        creditNoteChangeRate: xml_ncredito.getElementsByTagName('TASACAMBIO')[0].childNodes[0]?.nodeValue, //no implementado
                        creditNoteDuesQuantity: "0",
                        creditNoteType: creditNoteTypex
                    },
                    sylvania: true,
                    base64: base64,
                    detail: DETALLE,
                    provider: 'megaprint',
                    phrase: company.phrase,
                    addendum: ADENDASUMMARY,
                    xmlorigen: {
                        IDSERIE: xml_ncredito.getElementsByTagName('IDSERIE')[0].childNodes[0]?.nodeValue,
                        NODOCUMENTO: xml_ncredito.getElementsByTagName('NODOCUMENTO')[0].childNodes[0]?.nodeValue,
                        MONEDA: xml_ncredito.getElementsByTagName('MONEDA')[0].childNodes[0]?.nodeValue,
                        TASACAMBIO: xml_ncredito.getElementsByTagName('TASACAMBIO')[0].childNodes[0]?.nodeValue,
                        NITCONTRIBUYENTE: nit, 
                        NOMBRECONTRIBUYENTE:  (xml_ncredito.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(),
                        DIRECCIONCONTRIBUYENTE: address, 
                        FECHAEMISION: xml_ncredito.getElementsByTagName('FECHAEMISION')[0].childNodes[0]?.nodeValue,
                        VALORNETO: xml_ncredito.getElementsByTagName('VALORNETO')[0].childNodes[0]?.nodeValue,
                        IVA: xml_ncredito.getElementsByTagName('IVA')[0].childNodes[0]?.nodeValue,
                        EXENTO: xml_ncredito.getElementsByTagName('EXENTO')[0].childNodes[0]?.nodeValue,
                        TOTAL: xml_ncredito.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue,
                        DESCUENTO: xml_ncredito.getElementsByTagName('DESCUENTO')[0].childNodes[0]?.nodeValue,
                        EMPRESA: xml_ncredito.getElementsByTagName('EMPRESA')[0].childNodes[0]?.nodeValue,
                        SUCURSAL: xml_ncredito.getElementsByTagName('SUCURSAL')[0].childNodes[0]?.nodeValue,
                        CAJA: xml_ncredito.getElementsByTagName('CAJA')[0].childNodes[0]?.nodeValue,
                        USUARIO: xml_ncredito.getElementsByTagName('USUARIO')[0].childNodes[0]?.nodeValue,
                        GENERACION: xml_ncredito.getElementsByTagName('GENERACION')[0].childNodes[0]?.nodeValue,
                        RESOLUCION: xml_ncredito.getElementsByTagName('RESOLUCION')[0].childNodes[0]?.nodeValue,
                        NOFACTURA: xml_ncredito.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue,
                        TIPO_DOC: dte,
                        RESOLUCIONFACTURA: xml_ncredito.getElementsByTagName('RESOLUCIONFACTURA')[0].childNodes[0]?.nodeValue,
                        SERIEFACTURA: xml_ncredito.getElementsByTagName('SERIEFACTURA')[0].childNodes[0]?.nodeValue,
                        FECHAFACTURA: xml_ncredito.getElementsByTagName('FECHAFACTURA')[0].childNodes[0]?.nodeValue,
                        CONCEPTO: xml_ncredito.getElementsByTagName('CONCEPTO')[0].childNodes[0]?.nodeValue
                    }
                }

            } else if (dte === 'FACTURA' || dte === 'WALMART') {
                const xml_factura = xml.getElementsByTagName('LOTE')[0].getElementsByTagName('DOCUMENTOS')[0].getElementsByTagName(dte)[0]?.getElementsByTagName('ENCABEZADO')[0];
                const xml_opcional = xml.getElementsByTagName('LOTE')[0].getElementsByTagName('DOCUMENTOS')[0]?.getElementsByTagName('OPCIONAL')[0];


                let document = {
                    document: xml_factura.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue,
                    type: "1"
                };
                const searchDocument = await this.searchDocument(document); //Validar si ya se firmo el documento
                if(searchDocument.records.length > 0){
                    let jsonerror = {
                        errorDocument: true,
                        errorDetail: "El documento ya fue generado, "
                    }
                    return jsonerror;
                }

                let typeClientXML = xml_factura.querySelector('NITCONTRIBUYENTE');
                let typeClient = "0";
                let nit = "";
                let Total = 0;
                let TipoDocumento = "FCAM";
                
                if(typeClientXML !== null){
                    typeClient = "0";
                    console.log('Cliente Tipo ->', typeClient);
                    nit = xml_factura.getElementsByTagName('NITCONTRIBUYENTE')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                }
                else{
                    let typeClientXML_C = xml_factura.querySelector('CUI');
                    
                    if(typeClientXML_C !== null){
                        typeClient = "1";
                        console.log('Cliente Tipo ->',typeClient);
                        nit = xml_factura.getElementsByTagName('CUI')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                        if (nit.length < 13){
                            let jsonerror = {
                                errorClient: true,
                                errorDetail: "CUI no cumple con la longitud autorizada"
                            }
                            return jsonerror;
                        }
                    }
                    else{
                        let typeClientXML_E = xml_factura.querySelector('EXT');
                    
                        if(typeClientXML_E !== null){
                            typeClient = "2";
                            console.log('Cliente Tipo ->',typeClient);
                            nit = xml_factura.getElementsByTagName('EXT')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');
                            TipoDocumento = "FCAM";
                        }
                        else{
                            console.log('NO EXISTE');
                            return false;
                        }
                    }
                }

                Total = parseFloat(xml_factura.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue);
                console.log('Identificador->',nit);
                console.log('Total->',Total);

                if(Total >= 2500){
                    console.log('Validar CF');
                    
                    if(nit === "CF"){
                        return true;
                    }
                }
                else{
                    console.log('OK CF');
                }

                //const nit = xml_factura.getElementsByTagName('NITCONTRIBUYENTE')[0].childNodes[0]?.nodeValue.replace(/[ -]/g, '');                
                const address = (xml_factura.getElementsByTagName('DIRECCIONCONTRIBUYENTE')[0]?.textContent).trim();
                const name = (xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim();
                                
                const client = await this.clientValidation(nit, company.companyCode, address, typeClient, name);                

                if(client.hasOwnProperty('error')){
                    let jsonerror = {
                        errorClient: true,
                        errorDetail: client.DetalleError
                    }
                    return jsonerror;
                }

                if (client.error) { return false; };

                const invoiceExchangeDate = await this.dateES_US(xml_factura.getElementsByTagName('FECHAEMISION')[0].childNodes[0]?.nodeValue, '/');
                const invoiceExchangeSubscriptionDate = await this.dateES_US(xml_opcional.getElementsByTagName('OPCIONAL17')[0].childNodes[0]?.nodeValue, '/');

                const ADENDASUMMARY = {
                    //valor1: xml_opcional.getElementsByTagName('OPCIONAL11')[0].childNodes[0]?.nodeValue,
                    valor1: xml_factura.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue,
                    valor2: 'CLIENTE',
                    valor3: 'TELEFONO',
                    valor4: 'OBSERVACIONES',
                    valor5: xml_factura.getElementsByTagName('FECHAEMISION')[0].childNodes[0]?.nodeValue,
                    valor6: xml_opcional.getElementsByTagName('OPCIONAL11')[0].childNodes[0]?.nodeValue,
                    valor7: xml_opcional.getElementsByTagName('OPCIONAL7')[0].childNodes[0]?.nodeValue,
                    valor8: xml_opcional.getElementsByTagName('OPCIONAL13')[0].childNodes[0]?.nodeValue,
                    valor9: xml_opcional.getElementsByTagName('OPCIONAL14')[0].childNodes[0]?.nodeValue,
                    valor10: xml_opcional.getElementsByTagName('OPCIONAL15')[0].childNodes[0]?.nodeValue,
                    valor11: xml_opcional.getElementsByTagName('OPCIONAL16')[0].childNodes[0]?.nodeValue,
                    valor12: xml_opcional.getElementsByTagName('OPCIONAL12')[0].childNodes[0]?.nodeValue,
                    valor13: xml_opcional.getElementsByTagName('OPCIONAL18')[0].childNodes[0]?.nodeValue,
                    valor14: xml_opcional.getElementsByTagName('OPCIONAL8')[0].childNodes[0]?.nodeValue,
                    valor15: xml_opcional.getElementsByTagName('OPCIONAL10')[0].childNodes[0]?.nodeValue,
                    valor16: xml_opcional.getElementsByTagName('OPCIONAL6')[0].childNodes[0]?.nodeValue,
                    valor17: xml_opcional.getElementsByTagName('OPCIONAL9')[0].childNodes[0]?.nodeValue,
                    valor18: xml_opcional.getElementsByTagName('OPCIONAL17')[0].childNodes[0]?.nodeValue,
                    valor19: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0]?.nodeValue,
                    valor20: xml_opcional.getElementsByTagName('OPCIONAL2')[0].childNodes[0]?.nodeValue,
                    valor21: xml_opcional.getElementsByTagName('OPCIONAL4')[0].childNodes[0]?.nodeValue,
                    valor23: xml_opcional.getElementsByTagName('OPCIONAL5')[0].childNodes[0]?.nodeValue
                };

                const ComplementExp = {
                    NombreConsignatarioODestinatario: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0]?.nodeValue,
                    DireccionConsignatarioODestinatario: xml_opcional.getElementsByTagName('OPCIONAL4')[0].childNodes[0]?.nodeValue,
                    CodigoConsignatarioODestinatario: '-',
                    NombreComprador: (xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(),
                    DireccionComprador: address,
                    CodigoComprador: xml_opcional.getElementsByTagName('OPCIONAL13')[0].childNodes[0]?.nodeValue,
                    OtraReferencia: '-',
                    INCOTERM: 'CIF',
                    NombreExportador: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0]?.nodeValue,
                    CodigoExportador: 'C-05441'
                }

                let PaymentLine: any = new Array;

                await PaymentLine.push({
                    typePayment: 0,
                    NamePaymentType: 'Efectivo',
                    MontoPayment: parseFloat(xml_factura.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue)
                  });
                
                
                const json = {
                    store: {
                        storeCode: store.storeCode
                    },
                    user: {
                        userCode: userCode
                    },
                    transmitter: {
                        transmitterType: TipoDocumento,
                        transmitterCountry: 'GT',
                        transmitterMembership: 'GEN',
                        transmitterZipCode: store.storeZipCode,
                        transmitterEmail: company.companyEmail,
                        transmitterTaxDocument: company.companyTaxDocument,
                        transmitterEstablishmentCode: store.storeEstablishmentCode,
                        transmitterName: company.companyName.toUpperCase(),
                        transmitterAddress: store.storeAddress.toUpperCase(),
                        transmitterComercialName: store.storeName.toUpperCase(),
                        transmitterDepartment: store.storeDepartment.toUpperCase(),
                        transmitterMunicipality: store.storeMunicipality.toUpperCase(),
                        transmitterKey: company.companyKey,
                        transmitterToken: company.companyToken,
                        transmitterUser: company.companyPrefix,
                        transmitterPrefix: company.companyPrefix,
                        transmitterPhraseCode: parseInt(company.companyPhraseCode),
                        transmitterPhraseType: parseInt(company.companyPhraseType)
                    },
                    receiver: {
                        receiverAddress: address,
                        receiverCode: client.clientCode,
                        receiverCountry: 'GT',
                        receiverDeleted: 0,
                        receiverEmail: client.clientEmail,
                        receiverName: (xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(), //(xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(), //xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0].childNodes[0]?.nodeValue 
                        receiverStatus: 1,
                        receiverTaxDocument: nit,
                        receiverTypeTaxDocument: typeClient,
                        receiverZipCode: 0
                    },
                    invoice: {
                        invoiceCode: 0,
                        invoiceInternSerial: 'A',
                        invoicepaymentType: 0
                    },
                    invoiceExchange: {
                        invoiceExchangeCode: 1,
                        invoiceExchangeInternSerial: 'A',
                        invoiceExchangeTaxShortName: 'IVA',
                        invoiceExchangepaymentType: 0,
                        invoiceExchangeDate: invoiceExchangeDate,
                        invoiceExchangeComplementId: 'Cambiaria',
                        invoiceExchangeComplementName: 'Cambiaria',
                        invoiceExchangeCurrency: xml_factura.getElementsByTagName('MONEDA')[0].childNodes[0]?.nodeValue,
                        invoiceExchangeChangeRate: xml_factura.getElementsByTagName('TASACAMBIO')[0].childNodes[0]?.nodeValue,
                        invoiceExchangeTotal: parseFloat(xml_factura.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue),
                        invoiceExchangeTaxValue: parseFloat(xml_factura.getElementsByTagName('IVA')[0].childNodes[0]?.nodeValue),
                    },
                    detailsComplement: [
                        {
                            invoiceExchangeSubscriptionNumber: 1,
                            invoiceExchangeSubscriptionDate: invoiceExchangeSubscriptionDate,
                            invoiceExchangeSubscriptionAmount: parseFloat(xml_factura.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue)
                        }
                    ],
                    ComplementExport: {
                        NombreConsignatarioODestinatario: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0]?.nodeValue,
                        DireccionConsignatarioODestinatario: xml_opcional.getElementsByTagName('OPCIONAL4')[0].childNodes[0]?.nodeValue,
                        CodigoConsignatarioODestinatario: '-',
                        NombreComprador: (xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(),
                        DireccionComprador: address,
                        CodigoComprador: xml_opcional.getElementsByTagName('OPCIONAL13')[0].childNodes[0]?.nodeValue,
                        /*PaisConsignatario: xml_opcional.getElementsByTagName('PaisConsignatario')[0].childNodes[0]?.nodeValue,*/
                        OtraReferencia: '-',                        
                        INCOTERM: 'CIF',
                        NombreExportador: xml_opcional.getElementsByTagName('OPCIONAL1')[0].childNodes[0]?.nodeValue,
                        CodigoExportador: 'C-05441',
                        // LugarExpedicion: (xml_factura.getElementsByTagName('LUGAREXPEDICION')[0]?.textContent).trim(),
                        // PaisConsignatario: (xml_factura.getElementsByTagName('PAISCONSIGNATARIO')[0]?.textContent).trim(),
                    },
                    sylvania: true,
                    base64: base64,
                    detail: DETALLE,
                    paymentType: PaymentLine,
                    provider: 'megaprint',
                    phrase: company.phrase,
                    addendum: ADENDASUMMARY,
                    xmlorigen: {
                        IDSERIE: xml_factura.getElementsByTagName('IDSERIE')[0].childNodes[0]?.nodeValue,
                        NOFACTURA: xml_factura.getElementsByTagName('NOFACTURA')[0].childNodes[0]?.nodeValue,
                        MONEDA: xml_factura.getElementsByTagName('MONEDA')[0].childNodes[0]?.nodeValue,
                        TASACAMBIO: xml_factura.getElementsByTagName('TASACAMBIO')[0].childNodes[0]?.nodeValue,
                        NOMBRECONTRIBUYENTE:  (xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0]?.textContent).trim(), //xml_factura.getElementsByTagName('NOMBRECONTRIBUYENTE')[0].childNodes[0]?.nodeValue,
                        DIRECCIONCONTRIBUYENTE: address,
                        NITCONTRIBUYENTE: nit,
                        FECHAEMISION: xml_factura.getElementsByTagName('FECHAEMISION')[0].childNodes[0]?.nodeValue,
                        VALORNETO: xml_factura.getElementsByTagName('VALORNETO')[0].childNodes[0]?.nodeValue,
                        IVA: xml_factura.getElementsByTagName('IVA')[0].childNodes[0]?.nodeValue,
                        EXENTO: xml_factura.getElementsByTagName('EXENTO')[0].childNodes[0]?.nodeValue,
                        TOTAL: xml_factura.getElementsByTagName('TOTAL')[0].childNodes[0]?.nodeValue,
                        DESCUENTO: xml_factura.getElementsByTagName('DESCUENTO')[0].childNodes[0]?.nodeValue,
                        EMPRESA: xml_factura.getElementsByTagName('EMPRESA')[0].childNodes[0]?.nodeValue,
                        SUCURSAL: xml_factura.getElementsByTagName('SUCURSAL')[0].childNodes[0]?.nodeValue,
                        CAJA: xml_factura.getElementsByTagName('CAJA')[0].childNodes[0]?.nodeValue,
                        USUARIO: xml_factura.getElementsByTagName('USUARIO')[0].childNodes[0]?.nodeValue,
                        GENERACION: xml_factura.getElementsByTagName('GENERACION')[0].childNodes[0]?.nodeValue,
                        RESOLUCION: xml_factura.getElementsByTagName('RESOLUCION')[0].childNodes[0]?.nodeValue,
                        TIPO_DOC: dte,
                    },
                    walmart: {
                        WMEnviarGLN: '',
                        WMFechaOrden: '',
                        WMNumeroOrden: '',
                        WMNumeroVendedor: '',
                        WMNumeroRecepcion: ''
                    }
                }

                walmart === false && (delete json.walmart);
                return json;
            }
        } else {
            return false;
        }
    }
}